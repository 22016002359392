import { Dispatch } from 'redux';
import { getJwtToken } from 'utils/jwtUtils';
import { deleteRequestOptions, getRequestOptions } from 'utils/requestOptions';
import {
  GET_MEMBERSHIPS_ERROR,
  GET_MEMBERSHIPS_SUCCESS,
  GETTING_MEMBERSHIPS,
  UNREGISTER_MEMBERSHIP_ERROR,
  UNREGISTER_MEMBERSHIP_SUCCESS,
  UNREGISTERING_MEMBERSHIP,
} from './actionsTypes';
import { IDeleteMembershipRequest } from './typings';
import { get } from 'api/actions/getAction';

const baseRoute = `${window.config.API_BASE_URL}/api/memberships`;

export const getMemberships = () => {
  const accessToken = getJwtToken();
  return get(baseRoute, [
    GET_MEMBERSHIPS_SUCCESS,
    GETTING_MEMBERSHIPS,
    GET_MEMBERSHIPS_ERROR,
  ], { authorization: `bearer ${accessToken}` });
};

export const unregisterMembership = (request: IDeleteMembershipRequest) => {
  return (dispatch: Dispatch): void => {
    const accessToken = getJwtToken();
    const requestOptions = deleteRequestOptions({ authorization: `bearer ${accessToken}` });
    dispatch({ type: UNREGISTERING_MEMBERSHIP });
    const url = `${baseRoute}/${request.owner}/${request.id}`;
    fetch(url, requestOptions)
      .then(async (res) => {
        if (!res.ok) {
          const error = res.status;
          dispatch({
            type: UNREGISTER_MEMBERSHIP_ERROR,
            payload: error,
          });
          return Promise.reject(error);
        }
        return;
      })
      .then((res) => {
        dispatch({
          type: UNREGISTER_MEMBERSHIP_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: UNREGISTER_MEMBERSHIP_ERROR,
          payload: err.message,
        });
      });
  };
};
