import { useState, createRef, ReactElement, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { ITheme } from 'constants/styles';
import { useIsMobile } from 'hooks/MediaQueries';
import { IInputValidation } from 'types/typings';
import { validate } from 'utils/stringUtils';
import { InputLabel } from './InputLabel';
import { IFlowState } from 'hooks/flowState';
interface IProps {
  type?: 'password' | 'text' | 'number';
  name: string;
  label?: string;
  value?: string | number;
  placeholder?: string;
  required?: boolean;
  expression?: (str: string) => boolean;
  onKeyEnter?: () => void;
  onChange?: (value: string | number) => void;
  maxLength?: number;
  minLength?: number;
  readonly?: boolean;
  readonlyTooltip?: string;
  validation?: (inputValidation: IInputValidation) => void;
  flowState?: IFlowState;
  tooltip?: ReactElement;
  autocomplete?: 'username' | 'new-password' | 'off';
}

interface IOptions {
  isMobile: boolean;
  isValid: boolean;
  required: boolean;
}

interface IState {
  value: string | number | undefined;
  focus: boolean;
}

export const Input = ({
  type = 'text',
  label,
  value,
  name,
  onChange = () => {},
  onKeyEnter = () => {},
  placeholder,
  required = false,
  maxLength,
  minLength,
  expression,
  validation,
  flowState,
  tooltip,
  readonly = false,
  autocomplete,
  readonlyTooltip,
}: IProps): JSX.Element => {
  const isMobile = useIsMobile();
  const [isValid, error] = validate({
    stringExpression: expression,
    maxLength,
    minLength,
    required,
    value,
  });
  const [state, setState] = useState<IState>({ focus: false, value });
  if (!validation && (expression || maxLength || minLength || required)) {
    console.log(`TextInput with name:${name} is missing property validation`);
  }
  if (validation && !(expression || maxLength || minLength || required)) {
    console.log(`TextInput with name:${name} is having validation, but no requirements`);
  }

  useEffect(() => {
    if (!state.value || state.value !== value) {
      setState((prev) => {
        return { ...prev, value: value };
      });
    }
  }, [state.value, value]);

  useEffect(() => {
    const myHref = createRef<HTMLDivElement>();
    if (validation) {
      validation({
        message: `${label ?? placeholder} ${error}`,
        id: name,
        value: state.value,
        isValid: () => isValid,
        scrollIntoView: () => myHref.current?.scrollIntoView({ behavior: 'smooth' }),
      });
    }
  }, [state]);

  // const handleFieldChange = (value: string | number): void => {
  //   setState((prev) => {
  //     return { ...prev, value: value };
  //   });
  // };

  const handleFocus = (value: boolean): void => {
    setState((prev) => {
      return { ...prev, focus: value };
    });
  };

  const handleBlur = (value: boolean): void => {
    setState((prev) => {
      return { ...prev, focus: value };
    });
  };

  flowState?.errorHref.current?.scrollIntoView({ behavior: 'smooth' });
  const validationError = flowState
    ? flowState.inputValidation.filter((item) => !item.isValid() && item.id === name)
    : [];
  const hasError = flowState && flowState.enableValidation && validationError.length > 0;
  const classes = useStyles({
    isMobile,
    isValid: !hasError ?? true,
    required,
  });

  // RED box when error
  // Error text as pointer to error
  // tooltip med glyphicons!

  return (
    <div className={classes.container}>
      <InputLabel
        errorHref={hasError ? flowState.errorHref : undefined}
        label={hasError ? validationError[0].message : label}
        htmlFor={name}
        error={hasError}
      />
      {readonly && (
        <div className={classes.inputContainer}>
          <div className={classes.readonly}>
            {state.value}
            <div className={classes.verified} title={readonlyTooltip}>
              &#9989;
            </div>
          </div>
        </div>
      )}
      {!readonly && (
        <div
          className={classes.inputContainer}
          onBlur={() => handleBlur(false)}
          onFocus={() => handleFocus(true)}
        >
          {required && <p className={classes.required}>*</p>}
          {tooltip && state.focus && <div className={classes.tooltipRight}>{tooltip}</div>}
          <input
            type={type}
            className={classes.input}
            placeholder={placeholder}
            id={`${name}-search`}
            name={name}
            value={state.value}
            onKeyUp={(e) => e.key.toLocaleLowerCase() === 'enter' && onKeyEnter()}
            onChange={(e) => onChange(e.target.value)}
            maxLength={maxLength}
            autoComplete={autocomplete}
            data-lpignore="true"
            alt={label ?? placeholder}
          />
        </div>
      )}
    </div>
  );
};

const useStyles = createUseStyles((theme: ITheme) => ({
  verified: {
    marginLeft: 'auto',
    cursor: 'help',
  },
  readonly: {
    display: 'flex',
    padding: '6px 12px 6px 12px',
    backgroundColor: theme.inputFieldBox,
    borderColor: theme.inputFieldBorder,
    borderWidth: '1px',
    borderRadius: '4px',
    borderStyle: 'solid',
    width: '300px',
  },
  input: ({ isMobile, isValid, required }: IOptions) => ({
    width: '300px',
    height: '34px',
    padding: '6px 12px 6px 12px',
    color: '#555',
    backgroundColor: theme.inputFieldBox,
    borderWidth: '1px',
    borderColor: isValid ? theme.inputFieldBorder : theme.errorColor,
    borderRadius: '4px',
    borderStyle: 'solid',
    outline: 'none',
    '&:focus': {
      border: `1px solid ${isValid ? theme.inputFieldFocusBorder : theme.errorColor}`,
    },
  }),
  tooltip: {
    position: 'absolute',
    top: '15px',
    width: '10px',
    right: '5px',
    cursor: 'help',
  },
  tooltipRight: {
    border: `1px solid ${theme.inputFieldFocusBorder}`,
    position: 'absolute',
    minWidth: '100px',
    minHeight: '35px',
    // top: '53px',
    top: '20px',
    left: '306px',
    zIndex: '1000',
    borderRadius: '4px',
    padding: '4px',
    listStyle: 'disc',
    backgroundColor: `${theme.backgroundColorHover}`,
    '&:after': {
      position: 'absolute',
      width: '0',
      top: '5px',
      left: '-11px',
      height: '0',
      // marginLeft: '10px',
      content: '""',
      color: `${theme.inputFieldFocusBorder}`,
      borderRight: '10px solid ',
      borderBottom: '10px solid transparent',
      borderTop: '10px solid transparent',
      borderLeft: '0',
    },
    '@media (max-width: 767px)': {
      left: '100px',
      width: '200px',
      bottom: '43px',
      top: 'auto',
      '&:after': {
        left: '2px',
        bottom: '-10px',
        top: 'auto',
        borderRight: '10px solid transparent',
        borderBottom: '0',
        borderTop: '10px solid ',
        borderLeft: '10px solid transparent',
      },
    },
  },
  inputContainer: {
    paddingTop: '20px',
    position: 'relative',
  },
  container: {
    display: 'flex',
    position: 'relative',
  },
  required: {
    position: 'absolute',
    color: theme.errorColor,
    fontSize: '20px',
    width: '10px',
    bottom: '0',
    left: '3px',
    margin: '0',
  },
}));
