import { IClearError, ISignOut } from 'api/actionsTypes';
import { IMessage } from 'types/typings';
import { ILoginResponse } from './typings';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGGING_IN = 'LOGGING_IN';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export interface ILoginAction {
  type: typeof LOGIN_SUCCESS;
  payload: ILoginResponse;
}

export interface ILoggingIn {
  type: typeof LOGGING_IN;
}

export interface ILoginError {
  type: typeof LOGIN_ERROR;
  payload: IMessage;
}

export type LoginActionTypes = ILoginAction | ILoggingIn | ILoginError | IClearError | ISignOut;
