import React, { useEffect, useState } from 'react';
import { IFlowState, useFlowState } from 'hooks/flowState';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { passwordResetRequest } from 'api/account/actions';
import { IPasswordResetRequestRequest } from 'api/account/typings';
import { AppState } from 'store/configureStore';
import { UpdateStates } from 'types/typings';
import { Layout } from 'Lagacy/Layout';
import { LoginMenu } from 'components/LoginMenu';
import { Input } from 'input/Input';
import { validateEmail, validatePhoneNumber } from 'utils/stringUtils';
import { Button } from 'input/Button';
import { Recaptcha } from 'input/Recaptcha';
import { HorizontalContainer } from 'components/containers/HorizontalContainer';
import { VerticalContainer } from 'components/containers/VerticalContainer';
import { globalStyles } from 'utils/styleUtils';
import { Messages } from 'components/containers/Messages';
import Cookies from 'universal-cookie';

const defaultflowState: IFlowState = {
  inputValidation: [],
  errorHref: React.createRef<HTMLDivElement>(),
};

export const PasswordResetRequest = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const globalClasses = globalStyles();
  const [flowState, setValidation, isValid] = useFlowState(defaultflowState);
  const [state, setState] = useState<IPasswordResetRequestRequest>({});
  const passwordResetRequestState = useAppSelector((state: AppState) => state.passwordResetRequest);

  const handleFieldChange = (name: string, value: string | number | undefined): void => {
    setState((prev) => {
      return { ...prev, [name]: value };
    });
  };

  useEffect(() => {
    if (passwordResetRequestState.state === UpdateStates.Updated
      && passwordResetRequestState.login) {
      window.location.href = passwordResetRequestState.login.uri;
    }
  }, [passwordResetRequestState.state, passwordResetRequestState.login]);


  const handleSaveButtonClick = (): void => {
    // Verify Required
    if (isValid()) {
      const cookies = new Cookies();
      const userNameAndPhoneNumber = btoa(`${state.email}:${state.phoneNumber}`);
      const now = new Date();
      now.setSeconds(now.getSeconds() + 300);
      cookies.set("userNameAndPhoneNumber", userNameAndPhoneNumber, {
        path: '/',
        secure: window.location.protocol === 'https:' ? true : false,
        expires: now,
        sameSite: 'strict',
      });

      dispatch(passwordResetRequest(state));
    }
  };

  return (
    <Layout loginMenu={<LoginMenu />}>
      <HorizontalContainer>
        <>
          <Messages />
          <VerticalContainer title="Resett passord">
            <ol className={globalClasses.orderedList}>
              {passwordResetRequestState.state === UpdateStates.Error && (
                <li>Resett passord feilet</li>
              )}

              <>
                <li>
                  <Input
                    placeholder="brukernavn / e-post"
                    maxLength={200}
                    minLength={8}
                    value={state.email}
                    name="login_email"
                    required
                    validation={setValidation}
                    expression={validateEmail}
                    onChange={(value) => handleFieldChange('email', value)}
                    onKeyEnter={handleSaveButtonClick}
                    flowState={flowState}
                  />
                </li>
                <li>
                  <Input
                    placeholder="telefon"
                    maxLength={200}
                    minLength={1}
                    value={state.phoneNumber}
                    name="login_phoneNumber"
                    required
                    validation={setValidation}
                    expression={validatePhoneNumber}
                    onChange={(value) => handleFieldChange('phoneNumber', value)}
                    flowState={flowState}
                  />
                </li>
                <li>
                  <li>
                    <Recaptcha validation={setValidation} flowState={flowState} />
                  </li>
                </li>
                <li>
                  <Button
                    buttonText="Start reset"
                    onClickSave={() => handleSaveButtonClick()}
                    state={passwordResetRequestState.state}
                  />
                </li>
              </>
            </ol>
          </VerticalContainer>
          <VerticalContainer>
            <section className="social" id="socialLoginForm">
              <h2>Resett passord</h2>
              Fyll ut e-postadresse og og telefonnummer. Om det finnes en bruker med disse verdiene satt, vil måtte logge inn med Vipps og får deretter mulighet til å sette nytt passord.
            </section>
          </VerticalContainer>
        </>
      </HorizontalContainer>
    </Layout>
  );
};
