import { IOptions, postRequestFormOptions, postRequestOptions } from 'utils/requestOptions';
import {
  CONFIRM_EMAILING,
  CONFIRM_EMAIL_ERROR,
  CONFIRM_EMAIL_SUCCESS,
  CREATE_USER_ERROR as USER_CREATE_ERROR,
  CREATE_USER_SUCCESS as USER_CREATE_SUCCESS,
  CREATING_USER as USER_CREATING,
  PASSWORD_RESETTING,
  PASSWORD_RESET_ERROR,
  PASSWORD_RESET_REQUESTING,
  PASSWORD_RESET_REQUEST_ERROR,
  PASSWORD_RESET_REQUEST_SUCCESS,
  PASSWORD_RESET_SUCCESS,
  USER_SSN_SETTING,
  USER_SSN_SET_ERROR,
  USER_SSN_SET_SUCCESS,
  VIPPS_IDENTIFYING,
  VIPPS_IDENTIFY_ERROR,
  VIPPS_IDENTIFY_SUCCESS,
  USER_EMAIL_AND_PHONENUMBER_SET_SUCCESS,
  USER_EMAIL_AND_PHONENUMBER_SET_ERROR,
  USER_EMAIL_AND_PHONENUMBER_SETTING,
} from '../account/actionsTypes';
import { LOGGING_IN, LOGIN_ERROR, LOGIN_SUCCESS } from '../oauth/actionsTypes';
import { request } from './fetchAction';

export type postSuccessActionTypes =
  | typeof USER_SSN_SET_SUCCESS
  | typeof LOGIN_SUCCESS
  | typeof VIPPS_IDENTIFY_SUCCESS
  | typeof CONFIRM_EMAIL_SUCCESS
  | typeof PASSWORD_RESET_SUCCESS
  | typeof PASSWORD_RESET_REQUEST_SUCCESS
  | typeof USER_CREATE_SUCCESS
  | typeof USER_EMAIL_AND_PHONENUMBER_SET_SUCCESS;
export type postingActionTypes =
  | typeof USER_SSN_SETTING
  | typeof LOGGING_IN
  | typeof VIPPS_IDENTIFYING
  | typeof CONFIRM_EMAILING
  | typeof PASSWORD_RESETTING
  | typeof PASSWORD_RESET_REQUESTING
  | typeof USER_CREATING
  | typeof USER_EMAIL_AND_PHONENUMBER_SETTING;
export type postErrorActionTypes =
  | typeof USER_SSN_SET_ERROR
  | typeof LOGIN_ERROR
  | typeof VIPPS_IDENTIFY_ERROR
  | typeof CONFIRM_EMAIL_ERROR
  | typeof PASSWORD_RESET_ERROR
  | typeof PASSWORD_RESET_REQUEST_ERROR
  | typeof USER_CREATE_ERROR
  | typeof USER_EMAIL_AND_PHONENUMBER_SET_ERROR;

export const post = <T>(
  data: T,
  url: string,
  [postSuccessAction, postingAction, postErrorAction]: [
    postSuccessActionTypes,
    postingActionTypes,
    postErrorActionTypes
  ],
  options?: IOptions,
  timeout?: number
) =>
  request(url, { ...postRequestOptions({ ...data }, options), timeout }, [
    postSuccessAction,
    postingAction,
    postErrorAction,
  ]);

export const postForm = <T>(
  data: T,
  url: string,
  [postSuccessAction, postingAction, postErrorAction]: [
    postSuccessActionTypes,
    postingActionTypes,
    postErrorActionTypes
  ],
  options?: IOptions,
  timeout?: number
) =>
  request(url, { ...postRequestFormOptions({ ...data }, options), timeout }, [
    postSuccessAction,
    postingAction,
    postErrorAction,
  ]);
