import Cookies from 'universal-cookie';
import { LoadStates } from 'types/typings';
import { accessTokenName, userInfo } from 'utils/jwtUtils';
import { ILoginResponse, ILoginState } from './typings';
import { LOGGING_IN, LOGIN_SUCCESS, LOGIN_ERROR, LoginActionTypes } from './actionsTypes';
import { CLEAR_ERROR, SIGN_OUT } from 'api/actionsTypes';

export const INITIAL_STATE: ILoginState = {
  state: LoadStates.Null,
};

export const loginReducer = (state = INITIAL_STATE, action: LoginActionTypes): ILoginState => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      setAccessToken(action.payload);
      return {
        payload: action.payload,
        jwtToken: userInfo(action.payload.access_token),
        state: LoadStates.Loaded,
      };
    case LOGGING_IN:
      return { ...state, state: LoadStates.Loading };
    case SIGN_OUT:
      clearAccessToken();
      return INITIAL_STATE;
    case CLEAR_ERROR:
      return INITIAL_STATE;
    case LOGIN_ERROR:
      return {
        ...state,
        state: LoadStates.Error,
      };

    default:
      return state;
  }
};

const setAccessToken = (login: ILoginResponse): void => {
  const cookies = new Cookies();
  const now = new Date();
  now.setSeconds(now.getSeconds() + (login.expires_in ?? 0));
  cookies.set(accessTokenName, login.access_token, {
    path: '/',
    secure: window.location.protocol === 'https:' ? true : false,
    expires: now,
    sameSite: 'strict',
  });
  console.log(`${accessTokenName} ${cookies.get(accessTokenName) ? 'set' : 'not set'}`);
};

const clearAccessToken = (): void => {
  const cookies = new Cookies();
  console.log(window.location);
  cookies.remove(accessTokenName, {
    path: '/',
    secure: window.location.protocol === 'https:' ? true : false,
    expires: new Date(1970, 1, 1),
  });
  console.log(`${accessTokenName} removed`);
};
