import { useDispatch } from "react-redux";
import { IMessage } from "types/typings";
import { API_MASSAGE_ADD } from "../api/error/actionTypes";


export const useMessage = (): [(message: IMessage) => void] => {
    const dispatch = useDispatch();

    const setMessage = (message: IMessage) => {
        dispatch({ type: API_MASSAGE_ADD, payload: message });

    }
    return [setMessage]
}