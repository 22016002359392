import { getUser } from 'api/account/actions';
import { HorizontalContainer } from 'components/containers/HorizontalContainer';
import { Messages } from 'components/containers/Messages';
import { VerticalContainer } from 'components/containers/VerticalContainer';
import { LoginMenu } from 'components/LoginMenu';
import { Users } from 'components/Users';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { useRequireRole } from 'hooks/requireRole';
import { Layout } from 'Lagacy/Layout';
import { useEffect } from 'react';
import { AppState } from 'store/configureStore';
import { LoadStates } from 'types/typings';
import { getJwtToken, userInfo } from 'utils/jwtUtils';

export const ManageUsers = (): JSX.Element => {
  const getUserState = useAppSelector((state: AppState) => state.getUser);
  const getUsersState = useAppSelector((state: AppState) => state.getUsers);
  const dispatch = useAppDispatch();
  const [requireRole] = useRequireRole();
  requireRole('SuperAdmin');
  const jwt = getJwtToken();
  const jwtToken = jwt ? userInfo(jwt!) : undefined;

  const isAdministrator = jwtToken && !!getUserState.user && getUserState.user.emailConfirmed;

  useEffect(() => {
    if (jwtToken) {
      if (getUserState.state === LoadStates.Null) {
        dispatch(getUser());
      }
    }
  }, [dispatch, jwtToken, getUserState.state]);

  return (
    <Layout loginMenu={<LoginMenu />}>
      <HorizontalContainer title={isAdministrator ? (getUsersState.state === LoadStates.Loaded ? `Brukere (${getUsersState.users.length})` : 'Brukere') : undefined}>
        <>
          <Messages />
          <VerticalContainer layout="col-md-12">{isAdministrator && <Users />}</VerticalContainer>
        </>
      </HorizontalContainer>
    </Layout>
  );
};
