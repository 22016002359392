export const Routes: {
  root: string;
  login: string;
  createUser: string;
  users: string;
  resetPassword: string;
  resetPasswordRequest: string;
  confirmEmail: string;
} = {
  root: '/',
  login: '/Login',
  createUser: '/CreateUser',
  users: '/Users',
  resetPassword: '/ResetPassword',
  resetPasswordRequest: '/ResetPasswordRequest',
  confirmEmail: '/ConfirmEmail',
};
