import { ITheme } from 'constants/styles';
import { createUseStyles } from 'react-jss';
import { CreateStates, isBusy, LoadStates, UpdateStates } from 'types/typings';

interface IProps {
  buttonText?: string | undefined;
  state: LoadStates | CreateStates | UpdateStates;
  onClickSave: () => void;
}

interface IOptions {
  state: LoadStates | CreateStates | UpdateStates;
}

export const Button = ({ state, buttonText, onClickSave }: IProps) => {
  const classes = useStyles({ state });

  return (
    <div className={classes.container}>
      <button
        type="button"
        disabled={isBusy(state)}
        value={buttonText}
        onClick={() => onClickSave()}
        className={classes.button}
      >
        <span className={classes.buttonText}>{buttonText}</span>
      </button>
    </div>
  );
};

const useStyles = createUseStyles((theme: ITheme) => ({
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(359deg)',
    },
  },
  container: {
    paddingTop: '10px'
  },
  button: ({ state }: IOptions) => ({
    position: 'relative',
    padding: '8px 20px',
    background: '#009579',
    border: 'none',
    outline: 'none',
    borderRadius: '2px',
    width: 'inherit',
    cursor: 'pointer',
    '&:active': {
      backgroundColor: '#007a63',
    },
    '&:after': isBusy(state)
      ? {
        content: '""',
        position: 'absolute',
        width: '16px',
        height: '16px',
        top: '0',
        bottom: '0',
        margin: 'auto',
        border: '4px solid transparent',
        borderTopColor: '#ffffff',
        borderRadius: '50%',
        animation: '$spin 1s ease infinite',
      }
      : {},
  }),
  buttonText: {
    color: '#ffffff',
    transition: 'all 0.2s',
    paddingRight: '5px',
  },
}));
