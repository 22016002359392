import { useEffect } from 'react';
import { format } from 'date-fns';
import { LoadStates, isPending, UpdateStates } from 'types/typings';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { AppState } from 'store/configureStore';
import { getMemberships, unregisterMembership } from 'api/membership/actions';
import { IAddressResponse, IMemberResponse, IMembershipResponse } from 'api/membership/typings';
import { Spinner } from './spinner';
import { DropdownMenu } from 'input/DropdownMenu';
import { globalStyles } from 'utils/styleUtils';
import { getLoginState } from 'utils/jwtUtils';
import { tableStyles } from 'styles/table';
import { createUseStyles } from 'react-jss';
import { ITheme } from 'constants/styles';

export const Memberships = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const getMembershipsState = useAppSelector((state: AppState) => state.getMemberships);
  const unregisterMembershipState = useAppSelector((state: AppState) => state.unregisterMembership);
  const globalClasses = globalStyles();
  const loginState = getLoginState();
  const tableClasses = tableStyles();
  const classes = useStyles();

  const handleUnRegisterButtonClick = (membership: IMembershipResponse): void => {
    // Verify Required
    if (membership.statusId === 1 || membership.statusId === 100) {
      const action = window.confirm(`Vil du avslutte medlemsskapet i ${membership.owner}?`);
      if (action) {
        dispatch(unregisterMembership({ id: membership.id, owner: membership.owner }));
      }
    }
  };

  useEffect(() => {
    if (unregisterMembershipState.state === UpdateStates.Updated) {
      // TODO: Fix multiple calls
      dispatch(getMemberships());
    }
  }, [dispatch, unregisterMembershipState.state]);

  useEffect(() => {
    if (loginState.state === LoadStates.Loaded && !isPending(getMembershipsState.state)) {
      // TODO: Fix multiple calls
      dispatch(getMemberships());
    }
  }, [dispatch, loginState.state, getMembershipsState.state]);

  const toAddress = (address: IAddressResponse): string => {
    let str = address.addressLine1.trim();
    str = `${str} ${address.zipCode} ${address.zipName}`.trimEnd();
    return str;
  };

  const toFullName = (member: IMemberResponse): string => {
    let str = member.firstName.trim();
    str += ` ${member.middleName ?? ''}`.trimEnd();
    str += ` ${member.lastName ?? ''}`.trimEnd();
    return str;
  };

  const toStatus = (statusId: number): string => {
    switch (statusId) {
      case 1:
        return 'Aktiv';
      case 2:
        return 'Under Arbeid';
      case 3:
        return 'Utmeldt';
      case 4:
        return 'Dublett';
      case 7:
        return 'Død';
      case 100:
        return 'Nettmedlem';
      case 199:
        return 'Ikke bosatt i Norge';

      default:
        return 'Annet';
    }
  };

  const toDateString = (dateString: string): string => {
    if (!dateString) {
      return `Invalid date '${dateString}'`;
    }
    let date = new Date(dateString);
    if (!date) {
      return `Invalid date '${dateString}'`;
    }
    return format(date, 'dd.MM.yyyy');
  };

  return (
    <div className="row">
      <div className={globalClasses.colMd12}>
        {getMembershipsState.state === LoadStates.Loading ? (
          <Spinner />
        ) : (
          <div>
            <table className={tableClasses.table}>
              <thead>
                <tr className={tableClasses.trHead}>
                  <th>Moske</th>
                  <th>Navn</th>
                  <th className={tableClasses.tdLandscape}>Adresse</th>
                  <th className={tableClasses.tdCompact}>Mobil</th>
                  <th className={tableClasses.tdCompact}>Fra dato</th>
                  <th className={tableClasses.tdLast}>Status</th>
                </tr>
              </thead>
              <tbody>
                {getMembershipsState.memberships.map((ms) => {
                  return (
                    <tr key={ms.id} className={tableClasses.tr}>
                      <td>{ms.owner}</td>
                      <td>{toFullName(ms.member)}</td>
                      <td className={tableClasses.tdLandscape}>{toAddress(ms.member.address)}</td>
                      <td className={tableClasses.tdCompact}>{ms.member.telephone2}</td>
                      <td className={tableClasses.tdCompact}> {toDateString(ms.fromDate)}</td>
                      <td>
                        <div className={classes.right}>
                          {ms.statusId !== 3 ? (
                            <DropdownMenu
                              text={toStatus(ms.statusId)}
                              options={[
                                {
                                  onItemClicked: () => handleUnRegisterButtonClick(ms),
                                  text: 'Meld ut!',
                                  glyphicon: 'right-arrow',
                                },
                              ]}
                            />
                          ) : (
                            toStatus(ms.statusId)
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = createUseStyles((theme: ITheme) => ({
  right: {
    textAlign: 'right',
  },
}));
