import { ITheme } from 'constants/styles';
import { createUseStyles } from 'react-jss';

export const tableStyles = createUseStyles((theme: ITheme) => ({
  trHead: {
    border: '1px solid transparent',
    backgroundColor: theme.backgroundColorHover,
    borderBottomColor: '#d0d0d0',
    height: '35px',
  },
  tr: {
    height: '30px',
    '&:hover': {
      backgroundColor: theme.backgroundColorHover,
    },
  },
  tdLast: {
    textAlign: 'right',
  },
  table: {
    marginTop: '20px',
    width: '100%',
    textAlign: 'left',
    '& td, th': {
      paddingRight: '5px',
      paddingLeft: '2px',
    },
  },
  tdCompact: {
    display: 'none',
    '@media (min-width: 720px)': {
      display: 'table-cell',
    },
  },
  tdLandscape: {
    display: 'none',
    '@media (min-width: 400px)': {
      display: 'table-cell',
    },
  },

}));
