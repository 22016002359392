import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { ManageMembership } from 'components/pages/ManageMembership';
import { createBrowserRouter, createHashRouter, RouterProvider } from 'react-router-dom';
import { PasswordReset } from 'components/forms/PasswordReset';
import { LoginForm } from 'components/forms/LoginForm';
import { PasswordResetRequest } from 'components/forms/PasswordResetVippsRequest';
import { Routes } from 'constants/routes';
import { ConfirmEmail } from 'components/forms/ConfirmEmail';
import { ThemeProvider } from 'react-jss';
import { themes } from 'constants/styles';
import { ManageUsers } from 'components/pages/ManageUsers';
import { CreateUser } from 'components/pages/CreateUserVipps';

const router = createBrowserRouter([
  {
    path: Routes.root,
    element: <ManageMembership />,
  },
  {
    path: Routes.resetPassword,
    element: <PasswordReset />,
  },
  {
    path: Routes.resetPasswordRequest,
    element: <PasswordResetRequest />,
  },
  {
    path: Routes.login,
    element: <LoginForm />,
  },
  {
    path: Routes.createUser,
    element: <CreateUser />,
  },
  {
    path: Routes.confirmEmail,
    element: <ConfirmEmail />,
  },
  {
    path: Routes.users,
    element: <ManageUsers />,
  }
]);

function App() {
  return (
    <ThemeProvider theme={themes}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
