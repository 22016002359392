import React from 'react';
import { IUserResponse } from "api/account/typings";
import { IState } from "components/Users";
import { Input } from "input/Input";
import { ExpanderComponentProps } from "react-data-table-component";
import { validateEmail, validatePhoneNumber } from "utils/stringUtils";
import { IFlowState, useFlowState } from "./flowState";
import { HorizontalContainer } from "components/containers/HorizontalContainer";
import { Button } from 'input/Button';
import { UpdateStates } from 'types/typings';

export const defaultflowState: IFlowState = {
    inputValidation: [],
    enableValidation: true,
    errorHref: React.createRef<HTMLDivElement>(),
};


interface IUserProps extends ExpanderComponentProps<IUserResponse> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    onKeyEnter?: (state: IState) => void;
    onChange?: (userId: string, email: string, phoneNumber: string) => void;
    getState?: (userId: string) => IState
}

export const UserRow: React.FC<IUserProps> = ({
    data,
    onChange = (userId, email, phoneNumber) => { },
    onKeyEnter = () => { },
    getState = (userId) => { return {} }
}: IUserProps) => {
    const state = getState(data.id);
    const [flowState, setValidation, isValid] = useFlowState(defaultflowState);
    const email = state?.email ?? data.email
    const phoneNumber = state?.phoneNumber ?? data.phoneNumber
    const onKeyEnterInternal = () => {
        if (isValid()) {
            onKeyEnter(state);
        }
    }
    return (
        <>
            <HorizontalContainer title={data.fullName}>
                <Input
                    name="phoneNumber"
                    label="Telefon"
                    expression={validatePhoneNumber}
                    required={true}
                    value={phoneNumber}
                    validation={setValidation}
                    onKeyEnter={onKeyEnterInternal}
                    flowState={flowState}
                    onChange={(value) => onChange(data.id, email, value as string)} />
                <Input
                    name="email"
                    label="E-post"
                    expression={validateEmail}
                    required={true}
                    value={email}
                    validation={setValidation}
                    onKeyEnter={onKeyEnterInternal}
                    flowState={flowState}
                    onChange={(value) => onChange(data.id, value as string, phoneNumber)} />
                <Button
                    buttonText={'Lagre'}
                    onClickSave={onKeyEnterInternal}
                    state={UpdateStates.Null}
                />
            </HorizontalContainer>
        </>
    );
};