import { IUserResponse } from "api/account/typings";
import { validateEmail, validatePhoneNumber } from "utils/stringUtils";
import { TableColumn } from 'react-data-table-component';

export const useUserTable = (): [
    TableColumn<IUserResponse>[],
    (user: IUserResponse) => boolean
] => {
    const expandableRowDisabled = (user: IUserResponse) => validateEmail(user.email) && validatePhoneNumber(user.phoneNumber);
    const columns = [
        {
            name: 'Navn',
            grow: 3,
            selector: (user: IUserResponse) => user.fullName,
            sortable: true
        }, {
            name: 'Telefon',
            grow: 1,
            selector: (user: IUserResponse) => user.phoneNumber,
            sortable: true,
            sortFunction: sortPhoneNumber,
            conditionalCellStyles: [
                {
                    when: (user: IUserResponse) => !validatePhoneNumber(user.phoneNumber),
                    style: {
                        color: 'red',
                    },
                }]
        }, {
            name: 'E-post',
            grow: 3,
            selector: (user: IUserResponse) => user.email,
            sortable: true,
            sortFunction: sortEmail,
            conditionalCellStyles: [
                {
                    when: (user: IUserResponse) => !validateEmail(user.email),
                    style: {
                        color: 'red',
                    },
                }]
        }, {
            name: 'E-post?',
            grow: .25,
            selector: (user: IUserResponse) => user.emailConfirmed ? 'Ja' : 'Nei',
        }, {
            name: 'P.Nr?',
            selector: (user: IUserResponse) => (user.ssnConfirmed ? 'Ja' : ''),
        }, {
            name: '',
            grow: .25,
            // selector: (user: IUserResponse) => user.ssnHasValue,
        }
    ];
    return [columns, expandableRowDisabled];
}



const sortEmail = (rowA: IUserResponse, rowB: IUserResponse) => {
    const a = validateEmail(rowA.email);
    const b = validateEmail(rowB.email);
    if (!a || !b) {
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }
    }
    return caseInsensitiveSort(rowA.email, rowB.email);
}

const sortPhoneNumber = (rowA: IUserResponse, rowB: IUserResponse) => {
    const a = validatePhoneNumber(rowA.phoneNumber);
    const b = validatePhoneNumber(rowB.phoneNumber);
    if (!a || !b) {
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }
    }
    return caseInsensitiveSort(rowA.email, rowB.email);
}


const caseInsensitiveSort = (rowA: string, rowB: string) => {
    const a = rowA.toLowerCase();
    const b = rowB.toLowerCase();

    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;
};

