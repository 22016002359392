import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IPasswordResetRequest } from 'api/account/typings';
import { validatePassword } from 'utils/stringUtils';
import { passwordReset } from 'api/account/actions';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { IFlowState, useFlowState } from 'hooks/flowState';
import { Layout } from 'Lagacy/Layout';
import { LoginMenu } from 'components/LoginMenu';
import { Routes } from 'constants/routes';
import { AppState } from 'store/configureStore';
import { CreateStates, IMessage, UpdateStates } from 'types/typings';
import { Input } from 'input/Input';
import { Button } from 'input/Button';
import { Recaptcha } from 'input/Recaptcha';
import { HorizontalContainer } from 'components/containers/HorizontalContainer';
import { VerticalContainer } from 'components/containers/VerticalContainer';
import { globalStyles } from 'utils/styleUtils';
import { Messages } from 'components/containers/Messages';
import { useLogin } from 'api/account/confirmLogin';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';
import { API_MASSAGE_ADD } from 'api/error/actionTypes';

interface IProps {
  redirect?: string;
}

const defaultflowState: IFlowState = {
  inputValidation: [],
  errorHref: React.createRef<HTMLDivElement>(),
};

export const CreateUser = ({ redirect = Routes.login }: IProps): JSX.Element => {
  const appDispatch = useAppDispatch();

  const dispatch = useDispatch();
  const [flowState, setValidation, isValid] = useFlowState(defaultflowState);
  const [state, setState] = useState<IPasswordResetRequest>({ userId: "", code: "" });
  const passwordResetState = useAppSelector((state: AppState) => state.passwordReset);
  const navigate = useNavigate();
  const globalClasses = globalStyles();
  const login = useLogin();

  useEffect(() => {
    if (login.user) {
      const user = login.user;
      setState({
        code: user.resetToken ?? "",
        userId: user.userId ?? ""
      });
    }
  }, [login.user]);

  useEffect(() => {
    if (passwordResetState.state === UpdateStates.Updated) {
      const message: IMessage = {
        status: "200",
        error: "Passord endret!",
      }
      dispatch({ type: API_MASSAGE_ADD, payload: message });
      navigate(redirect);
    }
  }, [navigate, redirect, passwordResetState.state]);

  const handleFieldChange = (name: string, value: string | number | undefined): void => {
    setState((prev) => {
      const next = { ...prev, [name]: value };
      return { ...next };
    });
  };

  const identified = login.isLoading === false;

  const handleSaveButtonClick = (): void => {
    // Verify Required fields
    if (isValid()) {
      appDispatch(passwordReset(state));
    }
  };

  return (
    <Layout loginMenu={<LoginMenu />}>
      <HorizontalContainer title="Opprett nytt passord">
        <>
          <Messages />
          <VerticalContainer>
            <ol id="disableLastpass" className={globalClasses.orderedList}>

              {identified && (
                <li>
                  <Input
                    required={true}
                    label="Passord"
                    placeholder="Passord"
                    maxLength={200}
                    value={state.password}
                    type="password"
                    name="user_password"
                    validation={setValidation}
                    expression={validatePassword}
                    onChange={(value) => handleFieldChange('password', value)}
                    flowState={flowState}
                    autocomplete="new-password"
                    onKeyEnter={handleSaveButtonClick}
                    tooltip={
                      <>
                        <ul
                          className={`${globalClasses.orderedList} ${globalClasses.orderedListValidator}`}
                        >
                          <li>Mer enn 8 tegn</li>
                          <li>Stor bokstav [A-Z]</li>
                          <li>Liten bokstav [a-z]</li>
                          <li>Tall [0-9]</li>
                          <li>Spesialtegn [@$!%*?&]</li>
                        </ul>
                      </>
                    }
                  />
                </li>
              )}
              {identified && (
                <li>
                  <Input
                    required={true}
                    label="Gjenta passord"
                    placeholder="Gjenta passord"
                    maxLength={200}
                    value={state.confirmPassword}
                    type="password"
                    name="user_confirmPassword"
                    validation={setValidation}
                    expression={(value) => state.password === value}
                    onChange={(value) => handleFieldChange('confirmPassword', value)}
                    flowState={flowState}
                    autocomplete="new-password"
                    onKeyEnter={handleSaveButtonClick}
                  />
                </li>
              )}
              {identified && (
                <li>
                  <Recaptcha validation={setValidation} flowState={flowState} />
                </li>
              )}
              <li>
                <Button
                  buttonText={identified ? 'Lagre' : 'Verifiser'}
                  onClickSave={handleSaveButtonClick}
                  state={identified ? passwordResetState.state : (login.isLoading ? CreateStates.Creating : CreateStates.Null)}
                />
              </li>
            </ol>
          </VerticalContainer>
          <VerticalContainer>
            <section className="social">
              Dersom du ønsker å se hvilken moske du er registrert hos, oppdatere
              medlemsinformasjonen eller melde ut kan du gjøre dette ved å registrere deg som bruker
              hos oss via vipps. For å kunne logge inn med Vipps må man ha Vipps appen installert på
              mobilen og rett mobiler når man oppretter bruker. Ta gjerne kontakt dersom du lurer på
              noe.
            </section>
          </VerticalContainer>
        </>
      </HorizontalContainer>
    </Layout>
  );
};
