import { getJwtToken } from 'utils/jwtUtils';
import {
  ICreateUserRequest,
  IPasswordResetRequestRequest,
  IPasswordResetRequest,
  IConfirmEmailRequest,
  IVippsIdentifyRequest,
  IUserSsnRequest as IUserSsnSetRequest,
  IUserUpdateEmailAndPhoneNumberRequest,
} from './typings';
import {
  CREATE_USER_ERROR,
  CREATE_USER_SUCCESS,
  CREATING_USER,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  GETTING_USER,
  PASSWORD_RESET_REQUESTING,
  PASSWORD_RESET_REQUEST_ERROR,
  PASSWORD_RESET_REQUEST_SUCCESS,
  PASSWORD_RESETTING,
  PASSWORD_RESET_ERROR,
  PASSWORD_RESET_SUCCESS,
  VIPPS_AUTHENTICATING,
  VIPPS_AUTHENTICATE_ERROR,
  VIPPS_AUTHENTICATE_SUCCESS,
  CONFIRM_EMAILING,
  CONFIRM_EMAIL_ERROR,
  CONFIRM_EMAIL_SUCCESS,
  VIPPS_IDENTIFYING,
  VIPPS_IDENTIFY_ERROR,
  VIPPS_IDENTIFY_SUCCESS,
  GET_USERS_ERROR,
  GETTING_USERS,
  GET_USERS_SUCCESS,
  USER_SSN_SET_SUCCESS,
  USER_SSN_SETTING,
  USER_SSN_SET_ERROR,
  USER_EMAIL_AND_PHONENUMBER_SET_SUCCESS,
  USER_EMAIL_AND_PHONENUMBER_SETTING,
  USER_EMAIL_AND_PHONENUMBER_SET_ERROR,
} from './actionsTypes';
import { post } from '../actions/postAction';
import { get } from 'api/actions/getAction';

const baseRoute = `${window.config.API_BASE_URL}/api/accounts`;

export const createUser = (request: ICreateUserRequest) =>
  post(request, `${baseRoute}/create`, [CREATE_USER_SUCCESS, CREATING_USER, CREATE_USER_ERROR]);

export const setUserSsn = (request: IUserSsnSetRequest) => {
  const accessToken = getJwtToken();
  return post(
    request,
    `${baseRoute}/user`,
    [USER_SSN_SET_SUCCESS, USER_SSN_SETTING, USER_SSN_SET_ERROR],
    {
      authorization: `bearer ${accessToken}`,
    }
  );
};

export const updateUserEmailAndPhoneNumber = (request: IUserUpdateEmailAndPhoneNumberRequest) => {
  const accessToken = getJwtToken();
  return post(
    request,
    `${baseRoute}/user/emailAndPhoneNumber`,
    [USER_EMAIL_AND_PHONENUMBER_SET_SUCCESS, USER_EMAIL_AND_PHONENUMBER_SETTING, USER_EMAIL_AND_PHONENUMBER_SET_ERROR],
    {
      authorization: `bearer ${accessToken}`,
    }
  );
};

export const getUser = () => {
  const accessToken = getJwtToken();
  return get(`${baseRoute}/user`, [GET_USER_SUCCESS, GETTING_USER, GET_USER_ERROR], {
    authorization: `bearer ${accessToken}`,
  });
};

export const getUsers = () => {
  const accessToken = getJwtToken();
  return get(`${baseRoute}/users`, [GET_USERS_SUCCESS, GETTING_USERS, GET_USERS_ERROR], {
    authorization: `bearer ${accessToken}`,
  });
};

export const passwordResetRequest = (request: IPasswordResetRequestRequest) =>
  post(request, `${baseRoute}/passwordResetVippsRequest`, [
    PASSWORD_RESET_REQUEST_SUCCESS,
    PASSWORD_RESET_REQUESTING,
    PASSWORD_RESET_REQUEST_ERROR,
  ], { 'x-vipps-environment': window.config.VIPPS_ENVIRONMENT });

export const passwordReset = (request: IPasswordResetRequest) =>
  post(request, `${baseRoute}/passwordReset`, [
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESETTING,
    PASSWORD_RESET_ERROR,
  ]);

export const verifyUserAuthenticated = () => {
  const accessToken = getJwtToken();
  return get(
    `${baseRoute}/user/verify`,
    [VIPPS_AUTHENTICATE_SUCCESS, VIPPS_AUTHENTICATING, VIPPS_AUTHENTICATE_ERROR],
    { authorization: `bearer ${accessToken}` }
  );
};

export const confirmEmail = (request: IConfirmEmailRequest) =>
  post(request, `${baseRoute}/confirmEmail`, [
    CONFIRM_EMAIL_SUCCESS,
    CONFIRM_EMAILING,
    CONFIRM_EMAIL_ERROR,
  ]);

export const identify = (request: IVippsIdentifyRequest) =>
  post(
    request,
    `${baseRoute}/identify`,
    [VIPPS_IDENTIFY_SUCCESS, VIPPS_IDENTIFYING, VIPPS_IDENTIFY_ERROR],
    { 'x-vipps-environment': window.config.VIPPS_ENVIRONMENT },
    300000
  ); // internaly times out after 5min
