import { IValidate } from 'types/typings';

export function isSubStringOf(expected: string, actual?: string): boolean {
  if (!actual) {
    return false;
  }
  return expected.toLowerCase().indexOf(actual.toLowerCase()) >= 0;
}

export function nullIfEmpty(value?: string): string | null {
  if (!value || value.length === 0) {
    return null;
  }
  return value;
}

export function undefinedIfEmpty(value?: string): string | undefined {
  if (!value || value.length === 0) {
    return undefined;
  }
  return value;
}

export const validateEmail = (value: string): boolean => {
  //eslint-disable-next-line
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gms;
  return regex.exec(value) !== null;
};

export const validatePhoneNumber = (value: string): boolean => {
  const regex = /^((0047)?|(\+47)?)[4|9]\d{7}$/gms;
  return regex.exec(value) !== null;
};

export const validateSsn = (value: string): boolean => validateNumber(value, 11);

export const validateNumber = (value: string, length: number): boolean => {
  const regex = /^\\d{${length}}$/gms;
  // const regex = /^\\d{8}$/gms;
  return regex.exec(value) !== null;
};

export const validatePassword = (password: string): boolean => {
  var re = {
    capital: /[A-Z]/,
    digit: /[0-9]/,
    special: /[@$!%*?&]/,
    except: /[æøåÆØÅ]/,
    full: /^[A-Za-z0-9@$!%*?&]{8,50}$/,
  };
  return (
    re.capital.test(password) &&
    re.digit.test(password) &&
    re.special.test(password) &&
    !re.except.test(password) &&
    re.full.test(password)
  );
};

const validationError = ({
  required = true,
  minLength = true,
  maxLength = true,
  stringExpression = true,
  numericExpression = true,
}): string => {
  if (!required) return 'er påkrevd';
  if (!minLength) return 'er for kort';
  if (!maxLength) return 'er for langt';
  if (!numericExpression) return 'er ikke gyldig tall';
  if (!stringExpression) return 'er ikke gyldig';
  return '';
};

export const validate = (validate: IValidate): [boolean, string] => {
  const isNumeric = Number.isFinite(validate.value);
  const required: boolean = validate.required ? !!validate.value : true; // Required
  if (isNumeric) {
    const value: number = validate.value as number;
    const numericExpression: boolean =
      validate.numericExpression && value ? validate.numericExpression(value) : true; // Custom
    return [required && numericExpression, validationError({ required, numericExpression })];
  }

  const value: string = validate.value as string;
  const minLength: boolean = validate.minLength ? (value ?? '').length >= validate.minLength : true; // MinLength
  const maxLength: boolean = validate.maxLength ? (value ?? '').length <= validate.maxLength : true; // MaxLength
  const stringExpression: boolean =
    validate.stringExpression && validate.value ? validate.stringExpression(value) : true; // Custom
  return [
    required && minLength && maxLength && stringExpression,
    validationError({ required, minLength, maxLength, stringExpression }),
  ];
};
