import { useRef, useState } from 'react';
import { getLoginState } from 'utils/jwtUtils';
import { useDispatch } from 'react-redux';
import { SIGN_OUT } from 'api/actionsTypes';
import { Routes } from 'constants/routes';
import { Link, useNavigate } from 'react-router-dom';
import { useOutsideElementCallback } from 'hooks/callbacks';
import { DropdownMenu } from 'input/DropdownMenu';
import { glyphiconStyles } from 'styles/glyphiconsRegular';
import { useStyles as useLayoutStyles } from 'Lagacy/Layout';
import { globalStyles as useGlobalStyles } from 'utils/styleUtils';
import { createUseStyles } from 'react-jss';
import { ITheme } from 'constants/styles';
interface IPageState {
  menuOpen: boolean;
}

export const LoginMenu = (): JSX.Element => {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const glyphiconClasses = glyphiconStyles();
  const layoutClasses = useLayoutStyles();
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const navigate = useNavigate();

  const [, setState] = useState<IPageState>({
    menuOpen: false,
  });

  const loginState = getLoginState();

  const handleUserToggleDropdownClick = () => {
    setState((prev) => {
      return { ...prev, menuOpen: !prev.menuOpen };
    });
  };
  useOutsideElementCallback(wrapperRef, handleUserToggleDropdownClick);

  const handleLogoutClick = () => {
    setState((prev) => {
      return { ...prev, menuOpen: false };
    });
    dispatch({ type: SIGN_OUT });
    navigate(Routes.root);
  };
  const jwtToken = loginState.jwtToken;
  const isAdmin = jwtToken?.role?.includes('Admin') ?? false;
  return (
    <>
      {jwtToken && (
        <div className={globalClasses.flexContainer}>
          <div className={globalClasses.flexItemRight}>
            <ul className={`${layoutClasses.nav} ${layoutClasses.navbarNav}`}>
              {isAdmin && <li>
                <Link
                  className={`${glyphiconClasses.glyphicon} ${glyphiconClasses.users}`}
                  title="Brukere"
                  to={Routes.users}
                >
                  Brukere
                </Link>
              </li>}
              {/* <li>
                <Link
                  title="Registrer bruker"
                  to={Routes.createUser}
                  className={`${glyphiconClasses.glyphicon} ${glyphiconClasses['user']}`}
                >
                  Registrer bruker
                </Link>
              </li> */}
            </ul>
          </div>
          <div className={`${!isAdmin ? globalClasses.flexItemRight : ''}`}>
            <DropdownMenu
              title={jwtToken ? `${jwtToken.given_name} ${jwtToken.family_name}` : undefined}
              text={jwtToken.unique_name}
              options={[
                { onItemClicked: handleLogoutClick, text: 'Logg ut', glyphicon: 'log-out' },
              ]}
              glyphicon={'leaf'}
            />
          </div>
        </div>
      )}
      {!jwtToken && (
        <div className={globalClasses.flexContainer}>
          <div className={globalClasses.flexItemRight}>
            <ul className={`${layoutClasses.nav} ${layoutClasses.navbarNav} ${classes.horizontal}`}>
              {/* <li className={`${glyphiconClasses.glyphicon} ${glyphiconClasses['user']}`}>
                <Link title="Registrer bruker" to={Routes.createUser}>
                  Registrer bruker
                </Link>
              </li> */}
              <li className={`${glyphiconClasses.glyphicon} ${glyphiconClasses['log-in']}`}>
                <Link to={Routes.login} title="Logg inn">
                  Logg inn
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export const useStyles = createUseStyles((theme: ITheme) => ({
  horizontal: {
    '& li': {
      fontSize: '16px',
      '@media (min-width: 768px)': {
        display: 'inline',
      },
    },
  },
}));
