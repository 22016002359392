import { IMessage } from 'types/typings';

export const API_ERROR = 'API_ERROR';
export const API_ERROR_CLEAR = 'API_ERROR_CLEAR';
export const API_MASSAGE_ADD = 'API_MASSAGE_ADD';

export const ReactStrictMessage: IMessage = {
  status: "400",
  error: "Something went wrong!",
  error_description: "Might have been React.Strict mode!"
}

export interface IApiErrorClear {
  type: typeof API_ERROR_CLEAR;
}
export interface IApiMessageAdd {
  type: typeof API_MASSAGE_ADD;
  payload: IMessage;
}

export interface IApiErrorAction {
  type: typeof API_ERROR;
  payload: IMessage;
}
export type ApiErrorActionTypes = IApiErrorAction | IApiErrorClear | IApiMessageAdd;
