import { ITheme } from 'constants/styles';
import { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useOutsideElementCallback } from 'hooks/callbacks';
import { globalStyles } from 'utils/styleUtils';
import { glyphicon, glyphiconStyles } from 'styles/glyphiconsRegular';
import { useStyles as navStyles } from 'Lagacy/Layout';

interface IOption {
  text: string;
  onItemClicked: () => void;
  title?: string;
  glyphicon?: glyphicon;
}

interface IProps {
  text?: string | undefined;
  title?: string | undefined;
  options: IOption[];
  glyphicon?: glyphicon;
}

interface IState {
  expanded: boolean;
}

export const DropdownMenu = ({ text, title, options, glyphicon }: IProps) => {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const glyphiconClasses = glyphiconStyles();
  const [state, setState] = useState<IState>({ expanded: false });
  const wrapperRef = useRef(null);
  const navClasses = navStyles();

  const handleToggle = () => {
    setState((prev) => {
      return { ...prev, expanded: !prev.expanded };
    });
  };
  useOutsideElementCallback(wrapperRef, handleToggle);

  return (
    <ul
      className={`${navClasses.nav} ${classes.arrow} ${navClasses.navbarNav} ${navClasses.navbarRight}`}
      ref={state.expanded ? wrapperRef : null}
    >
      <li className={state.expanded ? classes.navOpen : ''}>
        <a
          role="button"
          onClick={() => handleToggle()}
          className={`dropdown-toggle ${
            glyphicon ? `${glyphiconClasses.glyphicon} ${glyphiconClasses[glyphicon]}` : ''
          }`}
          title={title}
        >
          {text}
        </a>
        {state.expanded && (
          <ul className={classes.dropdownMenu}>
            {/* <li className={classes.divider}></li> */}
            {options.map((o, i) => (
              <li key={`dropdown-menu-${i}`}>
                <a
                  onClick={() => o.onItemClicked()}
                  role="button"
                  className={
                    o.glyphicon
                      ? `${glyphiconClasses.glyphicon} ${glyphiconClasses[o.glyphicon]}`
                      : ''
                  }
                  title={o.title}
                >
                  {o.text}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    </ul>
  );
};

const useStyles = createUseStyles((theme: ITheme) => ({
  dropdownMenu: {
    right: 'auto',
    left: '30px',
    position: 'absolute',
    marginTop: 0,
    borderRadius: '0 0 4px 4px',
    zIndex: '1000',
    float: 'left',
    minWidth: '100px',
    padding: '5px 0',
    margin: '2px 0 0',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: theme.backgroundColorContrast,
    backgroundClip: 'padding-box',
    border: '1px solid #ccc',
    boxShadow: '0 6px 12px rgba(0, 0, 0, .175)',
    '& > li': {
      marginBottom: '2px',
    },
    '& > li a': {
      display: 'block',
      cursor: 'pointer',
      padding: '3px 20px',
      clear: 'both',
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      backgroundColor: theme.backgroundColorHover,
    },
    '@media (min-width: 768px)': {
      right: 0,
      left: 'auto',
    },
  },
  divider: {
    height: '1px',
    backgroundColor: 'rgb(229, 229, 229)',
    margin: '9px 0px',
    overflow: 'hidden',
  },
  arrow: {
    '& > li > a:after': {
      content: '""',
      display: 'inline-block',
      width: 0,
      height: 0,
      marginLeft: '2px',
      verticalAlign: 'middle',
      borderTop: '4px solid',
      borderRight: '4px solid transparent',
      borderLeft: '4px solid transparent',
      marginRight: '-10px',
    },
  },
  navOpen: {
    '& > a, > a:hover, > a:focus': {
      backgroundColor: theme.backgroundColorHover,
      color: theme.fontColorSecondary,
    },
  },
}));
