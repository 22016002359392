import { ITheme } from 'constants/styles';
import { IFlowState } from 'hooks/flowState';
import { useIsMobile } from 'hooks/MediaQueries';
import { createRef, useEffect, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { IInputValidation } from 'types/typings';
import { createUseStyles } from 'react-jss';

interface IProps {
    flowState: IFlowState;
    validation: (inputValidation: IInputValidation) => void;
}

interface IOptions {
    isMobile: boolean;
    isValid: boolean;
}

export const Recaptcha = ({ flowState, validation }: IProps): JSX.Element => {
    const isMobile = useIsMobile();
    const [state, setState] = useState<string | undefined>(undefined);

    const handleReCapthaChange = (token: string | null): void => {
        setState(token ?? undefined);
    };

    const userRecaptcha: string = 'user_recaptcha';

    useEffect(() => {
        const myHref = createRef<HTMLDivElement>();
        validation({
            id: userRecaptcha,
            value: state,
            isValid: () => !!state,
            message: "Recaptcha må fullføres",
            scrollIntoView: () => myHref.current?.scrollIntoView({ behavior: 'smooth' }),
        })

    }, [state]);

    const validationError = flowState.inputValidation.filter(
        (item) => !item.isValid() && item.id === userRecaptcha
    );
    const hasError = flowState.enableValidation && validationError.length > 0;
    const classes = useStyles({ isMobile, isValid: !hasError ?? true });

    return (
        <div className={classes.container}>
            {hasError && <span className={classes.error}>
                {validationError[0].message}</span>}
            <div className={classes.recaptcha}>
                <ReCAPTCHA
                    sitekey='6Lfh_AQTAAAAACQoKGl7h3Gl3pDH2FpR6_xMqmUC'
                    onChange={handleReCapthaChange}
                />
            </div>
        </div>)
}

const useStyles = createUseStyles((theme: ITheme) => ({
    recaptcha: {
        paddingTop: '20px'
    },
    container: {
        position: 'relative',
        display: 'flex'
    },
    required: {
        color: theme.errorColor,
        fontSize: '20px',
        paddingRight: '5px',
        paddingTop: '15px',
        '@media only screen and (max-width: 400px)': {
            display: 'none',
        },
    },
    error: ({ isMobile, isValid }: IOptions) => ({
        color: theme.errorColor,
        position: 'absolute',
        left: '5px',
        bottom: '75px',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
    }),
}));
