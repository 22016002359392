import { Dispatch } from 'redux';
import { postErrorActionTypes, postSuccessActionTypes, postingActionTypes } from './postAction';
import { getErrorActionTypes, getSuccessActionTypes, gettingActionTypes } from './getAction';
import { RequestInitTimeout, fetchWithTimeout } from './fetchWithTimeout';
import { API_ERROR } from 'api/error/actionTypes';

export const request = (
  url: string,
  requestOptions: RequestInitTimeout,
  [successAction, actingAction, errorAction]: [
    postSuccessActionTypes | getSuccessActionTypes,
    postingActionTypes | gettingActionTypes,
    postErrorActionTypes | getErrorActionTypes
  ]
) => {
  return (dispatch: Dispatch): void => {
    dispatch({ type: actingAction });
    fetchWithTimeout(url, requestOptions)
      .then(async (res) => {
        const text = await res.text();
        const data = text.length ? JSON.parse(text) : {};
        data['status'] = res.status;
        if (!res.ok) {
          // dispatch({
          //     type: errorAction,
          // });
          // dispatch({
          //     type: API_ERROR,
          //     payload: data,
          // });
          return Promise.reject(data);
        }
        return data;
      })
      .then((res) => {
        dispatch({
          type: successAction,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: errorAction,
        });
        dispatch({
          type: API_ERROR,
          payload: err,
        });
      });
  };
};
