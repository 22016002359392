import { ISignOut } from 'api/actionsTypes';
import { IMembershipResponse } from './typings';

export const GET_MEMBERSHIPS_SUCCESS = 'GET_MEMBERSHIPS_SUCCESS';
export const GETTING_MEMBERSHIPS = 'GETTING_MEMBERSHIPS';
export const GET_MEMBERSHIPS_ERROR = 'GET_MEMBERSHIPS_ERROR';

export interface IGetMembershipsAction {
  type: typeof GET_MEMBERSHIPS_SUCCESS;
  payload: IMembershipResponse[];
}

export interface IGettingMemberships {
  type: typeof GETTING_MEMBERSHIPS;
}

export interface IGetMembershipsError {
  type: typeof GET_MEMBERSHIPS_ERROR;
  payload: string;
}

export type GetMembershipsActionTypes =
  | IGetMembershipsAction
  | IGettingMemberships
  | IGetMembershipsError
  | ISignOut;

export const UNREGISTER_MEMBERSHIP_SUCCESS = 'UNREGISTER_MEMBERSHIP_SUCCESS';
export const UNREGISTERING_MEMBERSHIP = 'UNREGISTERING_MEMBERSHIP';
export const UNREGISTER_MEMBERSHIP_ERROR = 'UNREGISTER_MEMBERSHIP_ERROR';

export interface IUnregisterMembershipAction {
  type: typeof UNREGISTER_MEMBERSHIP_SUCCESS;
}

export interface IUnregisteringMembership {
  type: typeof UNREGISTERING_MEMBERSHIP;
}

export interface IUnregisterMembershipError {
  type: typeof UNREGISTER_MEMBERSHIP_ERROR;
  payload: string;
}

export type UnregisterMembershipActionTypes =
  | IUnregisterMembershipAction
  | IUnregisteringMembership
  | IUnregisterMembershipError;
