import { useState } from 'react';
import { getJwtToken } from 'utils/jwtUtils';
import { getRequestOptions } from 'utils/requestOptions';

interface IResponse {
  isLoading: boolean | null;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean | null>>;
  isError: boolean;
  confirmEmail: (userId: string) => Promise<boolean | null>;
}

export const useConfirmEmail = (): IResponse => {
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [isError, setIsError] = useState(false);

  const confirmEmail = async (userId: string): Promise<boolean | null> => {
    const accessToken = getJwtToken();
    const baseRoute = `${window.config.API_BASE_URL}/api/accounts`;
    const url = `${baseRoute}/user/${userId}/verifyEmailConfirmed`;

    try {
      setIsLoading(true);
      setIsError(false);
      const response = await fetch(
        url,
        getRequestOptions({
          authorization: `bearer ${accessToken}`,
        })
      ).then(async (res) => {
        const text = await res.text();
        const data = text.length ? JSON.parse(text) : {};
        data['status'] = res.status;
        if (!res.ok) {
          return Promise.reject(data);
        }
        return data;
      });
      return response;
    } catch (error: any) {
      setIsError(true);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, setIsLoading, isError, confirmEmail };
};
