import { MEDIA_QUERIES } from 'constants/styles';
import { useMediaQuery } from 'react-responsive';

export const useIsMobile = (): boolean => {
  return useMediaQuery({ query: MEDIA_QUERIES.mobile.replace('@media ', '') });
};

export const useIsLandscape = (): boolean => {
  return useMediaQuery({ query: MEDIA_QUERIES.landscape.replace('@media ', '') });
};

export const useIsIpad = (): boolean => {
  return useMediaQuery({ query: MEDIA_QUERIES.ipad.replace('@media ', '') });
};
