import React from 'react';
import { createUseStyles } from 'react-jss';
import { ITheme } from 'constants/styles';
import { glyphicons } from 'styles/glyphiconsRegular';

export const Spinner = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.spinnerOverlay}>
      <div className={classes.spinnerContainer}>
        <p className={classes.spinnerImage}></p>
        <span className={classes.spinnerText}>Laster...</span>
      </div>
    </div>
  );
};

const useStyles = createUseStyles((theme: ITheme) => ({
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(359deg)',
    },
  },
  spinnerOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: theme.dropDownBorder,
    opacity: '0.5',
    zIndex: '100',
  },
  spinnerContainer: {
    display: 'grid',
    position: 'absolute',
    zIndex: '10',
    top: '50px',
    right: '50%',
  },
  spinnerImage: {
    display: 'inline-block',
    animation: '$spin 2s infinite linear',
    '&:after': {
      'font-size': '3em',
      content: glyphicons.spinner,
      fontFamily: 'FontAwesome',
    },
  },
  spinnerText: {
    margin: '-1px',
  },
}));
