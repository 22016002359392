import { Routes } from 'constants/routes';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getJwtToken, userInfo } from 'utils/jwtUtils';


// const requireRoles = (role: 'Admin' | 'SuperAdmin' | 'User'): void => {
//   const navigate = useNavigate();
//   const jwt = getJwtToken();

//   useEffect(() => {
//     if (!jwt) {
//       navigate(Routes.login);
//     } else {
//       const jwtToken = userInfo(jwt);
//       if (!jwtToken.role?.includes(role)) {
//         navigate(Routes.login);
//       }
//     }
//   }, [jwt, navigate, role]);
// };

// export const useRequireRole = (): (role: 'Admin' | 'SuperAdmin' | 'User') => void => requireRoles;

export const useRequireRole = (): [(role: 'Admin' | 'SuperAdmin' | 'User') => void] => {
  const navigate = useNavigate();
  const jwt = getJwtToken();

  const requireRole = (role: 'Admin' | 'SuperAdmin' | 'User') => {
    if (!jwt) {
      navigate(Routes.login);
    } else {
      const jwtToken = userInfo(jwt);
      if (!jwtToken.role?.includes(role)) {
        navigate(Routes.login);
      }
    }
  }
  return [requireRole];
};
