import {
  GETTING_USER,
  GETTING_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  VIPPS_AUTHENTICATE_ERROR,
  VIPPS_AUTHENTICATE_SUCCESS,
  VIPPS_AUTHENTICATING,
} from 'api/account/actionsTypes';
import {
  GETTING_MEMBERSHIPS,
  GET_MEMBERSHIPS_ERROR,
  GET_MEMBERSHIPS_SUCCESS,
} from 'api/membership/actionsTypes';
import { getRequestOptions, IOptions } from 'utils/requestOptions';
import { request } from './fetchAction';

export type getSuccessActionTypes =
  | typeof GET_USER_SUCCESS
  | typeof GET_USERS_SUCCESS
  | typeof VIPPS_AUTHENTICATE_SUCCESS
  | typeof GET_MEMBERSHIPS_SUCCESS;
export type gettingActionTypes =
  | typeof GETTING_USER
  | typeof GETTING_USERS
  | typeof VIPPS_AUTHENTICATING
  | typeof GETTING_MEMBERSHIPS;
export type getErrorActionTypes =
  | typeof GET_USER_ERROR
  | typeof GET_USERS_ERROR
  | typeof VIPPS_AUTHENTICATE_ERROR
  | typeof GET_MEMBERSHIPS_ERROR;

export const get = <T>(
  url: string,
  [getSuccessAction, gettingAction, getErrorAction]: [
    getSuccessActionTypes,
    gettingActionTypes,
    getErrorActionTypes
  ],
  options?: IOptions,
  timeout?: number
) =>
  request(url, { ...getRequestOptions(options), timeout }, [
    getSuccessAction,
    gettingAction,
    getErrorAction,
  ]);
