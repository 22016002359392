import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { confirmEmail } from 'api/account/actions';
import { LoginMenu } from 'components/LoginMenu';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { Layout } from 'Lagacy/Layout';
import { AppState } from 'store/configureStore';
import { UpdateStates } from 'types/typings';
import { HorizontalContainer } from 'components/containers/HorizontalContainer';
import { VerticalContainer } from 'components/containers/VerticalContainer';
import { globalStyles } from 'utils/styleUtils';

export const ConfirmEmail = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const confirmEmailState = useAppSelector((state: AppState) => state.confirmEmail);
  const globalClasses = globalStyles();

  const userId = searchParams.get('userId')!;
  const code = searchParams.get('code')!;

  const confirmParamsSet = !!userId && !!code;

  useEffect(() => {
    if (confirmParamsSet && confirmEmailState.state === UpdateStates.Null) {
      dispatch(
        confirmEmail({
          code: code,
          userId: userId,
        })
      );
    }
  }, [dispatch, confirmParamsSet, userId, code, confirmEmailState.state]);

  return (
    <Layout loginMenu={<LoginMenu />}>
      <HorizontalContainer>
        <>
          <VerticalContainer title="Bekreft e-post">
            <ol className={globalClasses.orderedList}>
              {!confirmParamsSet && confirmEmailState.state === UpdateStates.Null && (
                <li>Kan ikke bekrefte e-post</li>
              )}
              {confirmEmailState.state === UpdateStates.Error && <li>Bekreft e-post feilet</li>}
              {confirmEmailState.state === UpdateStates.Updated && <li>E-post bekreftet!</li>}
            </ol>
          </VerticalContainer>
          <VerticalContainer>
            <section className="social" id="socialLoginForm">
              TODO: Fyll inn info om epost bekreeftelse...
            </section>
          </VerticalContainer>
        </>
      </HorizontalContainer>
    </Layout>
  );
};
