import { ITheme } from 'constants/styles';
import { createUseStyles } from 'react-jss';

export const width = (layout: 'col-md-3' | 'col-md-4' | 'col-md-6' | 'col-md-12'): number => {
  switch (layout) {
    case 'col-md-12':
      return 1200 / 12;

    case 'col-md-6':
      return 600 / 12;

    case 'col-md-4':
      return 400 / 12;

    case 'col-md-3':
      return 300 / 12;

    default:
      return 100;
  }
};

export const globalStyles = createUseStyles((theme: ITheme) => ({
  flexItemRight: {
    '@media (min-width: 720px)': {
      marginLeft: 'auto',
    },
  },
  colMd3: {
    padding: '0 15px',
    position: 'relative',
    minHeight: '1px',
    textAlign: 'center',
    '@media (min-width: 720px)': {
      width: `${width('col-md-3')}%`,
    },
  },
  colMd4: {
    padding: '0 15px',
    textAlign: 'center',
    '@media (min-width: 720px)': {
      width: `${width('col-md-4')}%`,
    },
  },
  colMd6: {
    padding: '0 15px',
    textAlign: 'center',
    '@media (min-width: 720px)': {
      width: `${width('col-md-6')}%`,
    },
  },
  colMd12: {
    padding: '0 15px',
    textAlign: 'center',
  },
  right: {
    '@media (min-width: 720px)': {
      textAlign: 'right',
    },
  },
  left: {
    '@media (min-width: 720px)': {
      textAlign: 'left',
    },
  },
  center: {
    '@media (min-width: 720px)': {
      textAlign: 'center',
    },
  },
  flexContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '@media (min-width: 720px)': {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
  container: {
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    // border: '1px solid black',
    '@media (min-width: 720px)': {
      width: '700px',
    },
    '@media (min-width: 992px)': {
      width: '970px',
    },
    // '@media (min-width: 1200px)': {
    //   width: '1170px',
    // },
  },
  body: {
    minHeight: '200px',
  },
  orderedList: {
    listStyle: 'none',
    paddingInlineStart: '0px',
    whiteSpace: 'nowrap',
    '@media (min-width: 720px)': {
      paddingInlineStart: '20px',
    },
  },
  orderedListValidator: {
    listStyle: 'disc',
    paddingInlineStart: '20px',
    '@media (min-width: 720px)': {},
  },
}));
