interface IMediaQueries {
  mobile: string;
  portrait: string;
  landscape: string;
  ipad: string;
}
export const MEDIA_QUERIES: IMediaQueries = {
  mobile: '@media (max-width: 1024px)',
  portrait: '@media (orientation: portrait)',
  landscape: '@media (orientation: landscape)',
  ipad: '@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 2)',
};

export const themes: ITheme = {
  // background
  backgroundColor: '#0E0E0E',
  backgroundColorHeader: '#FFF2AD',
  backgroundColorContrast: '#FFFFFF',
  backgroundColorHover: '#f6f6f6',

  // borders
  borderColor: '#FFFFFF',
  borderColorContrast: '#000000',
  dropDownBorder: '#CECECE',

  // fontColors
  fontColorPrimary: '#5C5C5C', // Oppdatert
  fontColorSecondary: '#0E0E0E',
  fontColorContrast: '#000000',
  fontColorDisabled: '#4F4F4F',
  fontNavigationActive: '#FFFFFF',
  fontNavigationInactive: '#BABABA',
  labelFontColor: '#646464',

  // buttons
  primaryButton: '#0E0E0E',
  primaryButtonHover: '#162365',
  primaryButtonFocus: '#162365',
  primaryButtonActive: '#6D7ABB',
  primaryButtonFocusBorder: '#FFF02B',
  primaryButtonDisabled: '#CECECE',
  secondaryButton: '#FFFFFF',
  secondaryButtonBorder: '#000000',
  secondaryButtonHover: '#162365',
  secondaryButtonFocus: '#162365',
  secondaryButtonActive: '#6D7ABB',
  secondaryButtonFocusBorder: '#FFF02B',
  secondaryButtonDisabledBorder: '#CECECE',
  tertiaryButton: '#6D7ABB',

  // dividers
  searchBarDivider: '#CECECE',
  headerDivider: '#646464',
  rowDivider: '#393939',

  // input, hover, focus, error
  inputFieldBox: '#FFF',
  inputFieldBorder: '#CCC',
  inputFieldFocusBorder: '#0091d2',
  activeColor: '#FFF2AD',
  linkHover: '#6D7ABB',
  formUnderline: '#BABABA',
  checkedRadioButton: '#6D7ABB',
  errorColor: '#FF5B5B',
  okColor: 'green',
  dropDownHover: '#BCCEDD',
  glyphiconBackground: '#eee',
  table: {
    header: {
      backgroundColor: '#dadada',
    },
    row: {
      backgroundColor: '#fff',
      hover: {
        backgroundColor: '#ddd',
        selected: {
          backgroundColor: '#ccc',
        },
      },
      selected: {
        backgroundColor: '#eee',
      },
    },
    alternatingRow: {
      backgroundColor: '#f7f7f7',
      hover: {
        backgroundColor: '#d7d7d7',
        selected: {
          backgroundColor: '#c7c7c7',
        },
      },
      selected: {
        backgroundColor: '#e7e7e7',
      },
    },
  },
  mediaQueries: MEDIA_QUERIES,
};

export const fontSizes = {
  // tabel
  table: '0.875rem',
  emptyList: '1.25rem',

  // navigation
  navigation: 'min(2.8vw, 3rem)',

  // headers
  headerSmall: '1.5rem',
  headerMedium: '2.18rem',
  headerBig: '4.0625rem',

  // general info
  generalInfoLabel: '1.25rem',
  generalInfo: '1.25rem',

  // form
  formHeader: '1.5rem',
  formTextInput: '14px',
  formLabel: '14px',
  error: '0.875rem',
  formInputMobileWidth: '67%',
  formInputWidth: '57%',
  formLabelWidth: '33%',

  // buttons
  button: '1.125rem',
  radioButton: '1.125rem',
  checkBox: '1.125rem',
  backTo: '1rem',
  filterButton: '1rem',

  // counter
  counter: '1.125rem',

  // search
  search: '1.25rem',

  // mobile
  smallMobile: '0.7rem',
  mediumMobile: '0.9rem',
};

export interface ITheme {
  backgroundColor: string;
  backgroundColorHeader: string;
  backgroundColorContrast: string;
  backgroundColorHover: string;
  borderColor: string;
  borderColorContrast: string;
  fontColorPrimary: string;
  fontColorSecondary: string;
  fontColorContrast: string;
  fontNavigationActive: string;
  fontNavigationInactive: string;
  fontColorDisabled: string;
  labelFontColor: string;
  searchBarDivider: string;
  headerDivider: string;
  rowDivider: string;
  primaryButton: string;
  primaryButtonHover: string;
  primaryButtonFocus: string;
  primaryButtonActive: string;
  primaryButtonFocusBorder: string;
  primaryButtonDisabled: string;
  secondaryButton: string;
  secondaryButtonBorder: string;
  secondaryButtonHover: string;
  secondaryButtonFocus: string;
  secondaryButtonActive: string;
  secondaryButtonFocusBorder: string;
  secondaryButtonDisabledBorder: string;
  inputFieldBox: string;
  inputFieldBorder: string;
  inputFieldFocusBorder: string;
  activeColor: string;
  tertiaryButton: string;
  linkHover: string;
  formUnderline: string;
  checkedRadioButton: string;
  errorColor: string;
  okColor: string;
  dropDownBorder: string;
  dropDownHover: string;
  glyphiconBackground: string;
  table: {
    header: {
      backgroundColor: string;
    };
    row: {
      backgroundColor: string;
      selected: {
        backgroundColor: string;
      };
      hover: {
        backgroundColor: string;
        selected: {
          backgroundColor: string;
        };
      };
    };
    alternatingRow: {
      backgroundColor: string;
      selected: {
        backgroundColor: string;
      };
      hover: {
        backgroundColor: string;
        selected: {
          backgroundColor: string;
        };
      };
    };
  };
  mediaQueries: IMediaQueries;
}
