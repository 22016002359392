import React from 'react';
import { createUseStyles } from 'react-jss';
import { ITheme } from 'constants/styles';
import { width } from 'utils/styleUtils';

interface IProps {
  children: React.ReactNode;
  title?: string;
  layout?: 'col-md-6' | 'col-md-12' | 'col-md-4' | 'col-md-3';
}

interface IOptions {
  layout: 'col-md-6' | 'col-md-12' | 'col-md-4' | 'col-md-3';
}

export const VerticalContainer = ({
  children,
  title,
  layout = 'col-md-6',
}: IProps): JSX.Element => {
  const classes = useStyles({ layout });

  return (
    <div className={classes.vertical}>
      {title && (
        <hgroup className={classes.title}>
          <h1>{title}</h1>
        </hgroup>
      )}
      {children}
    </div>
  );
};

const useStyles = createUseStyles((theme: ITheme) => ({
  title: {
    marginBottom: '10px',
    paddingRight: '0',
    paddingLeft: '0',
    '@media (min-width: 720px)': {
      paddingLeft: '20px',
    },
  },
  vertical: ({ layout }: IOptions) => ({
    position: 'relative',
    width: '100%',
    paddingRight: '0',
    paddingLeft: '0',

    '@media (min-width: 720px)': {
      width: `${width(layout)}%`,
      paddingRight: '15px',
      paddingLeft: '15px',
    },
  }),
}));
