import React from 'react';
import { createUseStyles } from 'react-jss';

interface ISubmitProps {
  submitButtonText?: string | undefined;
  disabled: boolean;
  onClickSave: () => void;
}

export const SubmitButton = ({ disabled, submitButtonText, onClickSave }: ISubmitProps) => {
  const classes = useStyles();
  return (
    <div className={classes.submitContainer}>
      <input
        type="button"
        disabled={disabled}
        value={submitButtonText}
        onClick={() => onClickSave()}
        className={classes.submitButton}
      />
    </div>
  );
};

const useStyles = createUseStyles({
  submitContainer: {
    display: 'flex',
    padding: '1.5rem 0',
  },
  submitButton: {
    backgroundColor: '#336fb7',
    border: '1px solid #2e6da4',
    cursor: 'pointer',
    padding: '4px',
    marginRight: '8px',
    width: '130px',
    color: '#fff',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#28578f',
      borderColor: '#1d3f67',
    },
  },
});
