import { useEffect, useState } from 'react';
import { getJwtToken } from 'utils/jwtUtils';
import { getRequestOptions, postRequestOptions } from 'utils/requestOptions';
import { IVippsUserResponse } from './typings';
import { API_MASSAGE_ADD, ReactStrictMessage } from 'api/error/actionTypes';
import { useDispatch } from 'react-redux';
import { Routes } from 'constants/routes';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { IMessage } from 'types/typings';

interface IResponse {
  isLoading: boolean | null;
  isError: boolean;
  login: (phoneNumber: string) => void;
  user: IVippsUserResponse | undefined
}

interface ILoginUrlResponse {
  uri: string;
}

interface IVippsLoginUrlRequest {
  phoneNumber: string
}


export const useLogin = (): IResponse => {
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [isError, setIsError] = useState(false);
  const [user, setUser] = useState<IVippsUserResponse>();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code')!;

  useEffect(() => {
    if (isLoading === null && code && code.length > 10) {
      const url = Routes.root;
      const cookies = new Cookies();
      const cookie = cookies.get("userNameAndPhoneNumber");
      const [userName, phoneNumber] = atob(cookie ?? '').toLocaleUpperCase().split(':');

      confirmLogin(code, userName, phoneNumber).then((user) => {
        // redirect to root! remove signin query parameters
        if (user) {
          if (userName !== user.email.toLocaleUpperCase()
            || phoneNumber !== user.phoneNumber.substr(user.phoneNumber.length - 8)) {
            const message: IMessage = {
              status: "400",
              error: "Login feilet!",
              error_description: "Brukernavn / e-post eller telefonnummer var ikke det samme som startet endring av passord"
            }
            dispatch({ type: API_MASSAGE_ADD, payload: message });
          }
          setUser({ ...user });
        }
        else {
          window.location.href = url;
        }
      });
    }
  }, [code, isLoading]);

  const login = (phoneNumber: string): void => {
    if (isLoading === null) {
      getLoginUrl(phoneNumber!).then(res => {
        window.location.href = res.uri
      });
    }
  }

  const confirmLogin = async (code: string, email: string, phoneNumber: string): Promise<IVippsUserResponse> => {
    const accessToken = getJwtToken();
    const baseRoute = `${window.config.API_BASE_URL}/api/vipps`;
    const url = `${baseRoute}/identify?code=${code}&userName=${email}&phoneNumber=${phoneNumber}`;

    try {
      setIsLoading(true);
      setIsError(false);
      const response = await fetch(
        url,
        getRequestOptions({
          authorization: `bearer ${accessToken}`,
          "x-vipps-environment": window.config.VIPPS_ENVIRONMENT
        })
      ).then(async (res) => {
        const text = await res.text();
        const data = text.length ? JSON.parse(text) : {};
        data['status'] = res.status;
        if (!res.ok) {
          return Promise.reject(data);
        }
        return data;
      });
      return response;
    } catch (error: any) {
      setIsError(true);
      dispatch({ type: API_MASSAGE_ADD, payload: ReactStrictMessage });
      return Promise.reject(error);

    } finally {
      setIsLoading(false);
    }
  };

  const getLoginUrl = async (phoneNumber: string): Promise<ILoginUrlResponse> => {
    const accessToken = getJwtToken();
    const baseRoute = `${window.config.API_BASE_URL}/api/vipps`;
    const url = `${baseRoute}/loginUrlRequest`;
    const request: IVippsLoginUrlRequest = {
      phoneNumber
    }
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await fetch(
        url,
        postRequestOptions(request, {
          authorization: `bearer ${accessToken}`,
          "x-vipps-environment": window.config.VIPPS_ENVIRONMENT
        })
      ).then(async (res) => {
        const text = await res.text();
        const data = text.length ? JSON.parse(text) : {};
        data['status'] = res.status;
        if (!res.ok) {
          return Promise.reject(data);
        }
        return data;
      });
      return response;
    } catch (error: any) {
      setIsError(true);
      dispatch({ type: API_MASSAGE_ADD, payload: error });
      return Promise.reject(error);

    } finally {
      setIsLoading(false);
    }
  };


  return { isLoading, isError, login, user };
};
