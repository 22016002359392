import React, { useState } from 'react';
import { IInputValidation, StatusMessage } from 'types/typings';

export interface IFlowState {
  errorHref: React.RefObject<HTMLDivElement>;
  statusMessage?: StatusMessage;
  enableValidation?: boolean;
  inputValidation: IInputValidation[];
}

export const useFlowState = (
  flowstate: IFlowState
): [
    IFlowState,
    (validation: IInputValidation) => void,
    () => boolean
  ] => {
  const [flowState, setFlowState] = useState(flowstate);

  const setValidation = (validation: IInputValidation): void => {
    const inputValidation = flowState.inputValidation;
    const error = validation;
    const existing = inputValidation.find((item) => item.id === error.id);
    if (existing) {
      if (existing.message !== error.message || existing.isValid() !== error.isValid()) {
        const index = inputValidation.indexOf(existing);
        inputValidation[index] = error;
        setFlowState((prev) => {
          return { ...prev, inputValidation };
        });
      }
    } else {
      inputValidation.push(error);
      setFlowState((prev) => {
        return { ...prev, inputValidation };
      });
    }
  };

  const isValid = (): boolean => {
    // Verify Required
    setFlowState((prev) => {
      return { ...prev, enableValidation: true };
    });
    const invalid = flowState.inputValidation.filter((validation) => !validation.isValid());

    if (invalid.length !== 0) {
      // Force extra render to cope with scrollIntoView()
      setTimeout(
        () =>
          setFlowState((prev) => {
            return { ...prev };
          }),
        100
      );
      return false;
    }
    setFlowState((prev) => {
      return { ...prev, enableValidation: false };
    });
    return true;
  };

  return [flowState, setValidation, isValid];
};
