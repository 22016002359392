import { IMessage, LoadStates } from 'types/typings';
import { IApiErrorState } from './typings';
import { API_ERROR, API_ERROR_CLEAR, API_MASSAGE_ADD, ApiErrorActionTypes } from './actionTypes';

const API_ERROR_INITIAL_STATE: IApiErrorState = {
  state: LoadStates.Null,
  errors: [],
};

export const errorReducer = (
  state = API_ERROR_INITIAL_STATE,
  action: ApiErrorActionTypes
): IApiErrorState => {
  switch (action.type) {
    case API_ERROR:
    case API_MASSAGE_ADD:
      state.errors.push(parseError(action.payload));
      return {
        ...state,
        state: LoadStates.Error,
      };
    case API_ERROR_CLEAR:
      return {
        ...state,
        errors: [],
        state: LoadStates.Null,
      };
    default:
      return state;
  }
};

interface IInternalError {
  message?: string;
}

interface IHttpError {
  exceptionMessage?: string;
}

interface IModelStateError {
  message?: string;
  modelState?: {
    [key: string]: string[];
  };
}

const parseError = (err: IMessage & IInternalError & IHttpError & IModelStateError): IMessage => {
  return {
    error: err.message || err.error,
    error_description:
      err.error_description ||
      err.exceptionMessage ||
      (err.modelState ? Object.values(err.modelState).join() : undefined),
    status: err.status,
    stack: err.stack,
  };
};
