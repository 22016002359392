export interface IOptions {
  authorization?: string;
  'x-vipps-environment'?: string;
}

export const getRequestOptions = (options?: IOptions): RequestInit => {
  return {
    method: 'GET',
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      ...options,
      'Content-Type': 'application/json',
    }
  };
};

export const postRequestOptions = <T>(data: T, options?: IOptions): RequestInit => {
  return {
    method: 'POST',
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      ...options,
      'Content-Type': 'application/json',
    },

    body: JSON.stringify(data),
  };
};

const createFormData = (request: any): string => {
  return Object.keys(request)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(request[key]))
    .join('&');
};

export const postRequestFormOptions = <T>(data: T, options?: IOptions): RequestInit => {
  return {
    method: 'POST',
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      ...options,
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: createFormData(data),
  };
};

export const putRequestOptions = <T>(data: T): RequestInit => {
  return {
    method: 'PUT',
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
};

export const deleteRequestOptions = (options?: IOptions): RequestInit => {
  return {
    method: 'DELETE',
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      ...options,
      'Content-Type': 'application/json',
    },
  };
};
