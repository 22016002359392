export const SIGN_OUT = 'SIGN_OUT';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export interface ISignOut {
    type: typeof SIGN_OUT;
}

export interface IClearError {
    type: typeof CLEAR_ERROR;
}
