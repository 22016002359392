import { ITheme } from 'constants/styles';
import { useAppSelector } from 'hooks/hooks';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { AppState } from 'store/configureStore';
import { IMessage } from 'types/typings';
import { Message } from './Message';

export const Messages = (): JSX.Element => {
  const classes = useStyles();
  const apiErrorState = useAppSelector((state: AppState) => state.apiError);
  const [, setState] = useState(-1);

  const handleMessageClose = (message: IMessage) => {
    const index = apiErrorState.errors.indexOf(message);
    if (index > -1) {
      apiErrorState.errors.splice(index, 1);
    }
    // Force update
    setState(Date.now().valueOf());
  };
  return (
    <>
      {apiErrorState.errors.length > 0 && (
        <div className={`${classes.messages} `}>
          {apiErrorState.errors.map((error, index) => (
            <Message
              key={`error-${index}`}
              message={error?.error_description!}
              type={error.status === "200" ? 'information' : 'error'}
              title={error?.error!}
              ttl={error.ttl}
              onClose={() => handleMessageClose(error)}
            />
          ))}
        </div>
      )}
    </>
  );
};

const useStyles = createUseStyles((theme: ITheme) => ({
  messages: {
    position: 'absolute',
    right: '0px',
    width: `100%`,
    zIndex: 1,
    '@media (min-width: 720px)': {
      width: `330px`,
      marginRight: '15px',
    },
    '@media (min-width: 992px)': {
      width: `440px`,
    },
    '@media (min-width: 1200px)': {
      width: `540px`,
    },
  },
}));
