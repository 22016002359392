import { LoadStates, DeleteStates, UpdateStates } from 'types/typings';
import {
  IGetMembershipsState,
  IDeleteMembershipState as IUnregisterMembershipState,
} from './typings';
import {
  GET_MEMBERSHIPS_SUCCESS,
  GETTING_MEMBERSHIPS,
  GET_MEMBERSHIPS_ERROR,
  GetMembershipsActionTypes,
  UNREGISTERING_MEMBERSHIP,
  UNREGISTER_MEMBERSHIP_ERROR,
  UNREGISTER_MEMBERSHIP_SUCCESS,
  UnregisterMembershipActionTypes,
} from './actionsTypes';
import { SIGN_OUT } from 'api/actionsTypes';

export const GET_MEMBERSHIPS_INITIAL_STATE: IGetMembershipsState = {
  memberships: [],
  state: LoadStates.Null,
};

export const getMembershipsReducer = (
  state = GET_MEMBERSHIPS_INITIAL_STATE,
  action: GetMembershipsActionTypes
): IGetMembershipsState => {
  switch (action.type) {
    case GET_MEMBERSHIPS_SUCCESS:
      return {
        memberships: action.payload,
        state: LoadStates.Loaded,
      };
    case GETTING_MEMBERSHIPS:
      return { ...state, state: LoadStates.Loading };
    case SIGN_OUT:
      return GET_MEMBERSHIPS_INITIAL_STATE;
    case GET_MEMBERSHIPS_ERROR:
      return {
        ...state,
        state: LoadStates.Error,
      };
    default:
      return state;
  }
};

export const DELETE_MEMBERSHIP_INITIAL_STATE: IUnregisterMembershipState = {
  state: UpdateStates.Null,
};

export const unregisterMembershipReducer = (
  state = DELETE_MEMBERSHIP_INITIAL_STATE,
  action: UnregisterMembershipActionTypes
): IUnregisterMembershipState => {
  switch (action.type) {
    case UNREGISTER_MEMBERSHIP_SUCCESS:
      return {
        state: UpdateStates.Updated,
      };
    case UNREGISTERING_MEMBERSHIP:
      return {
        ...state,
        state: UpdateStates.Updating,
      };
    case UNREGISTER_MEMBERSHIP_ERROR:
      return {
        ...state,
        state: UpdateStates.Error,
      };
    default:
      return state;
  }
};
