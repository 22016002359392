import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { AppActions } from 'actions/appActions';
import {
  createUserReducer,
  getUserReducer,
  passwordResetRequestReducer,
  passwordResetReducer,
  verifyUserAuthenticatedReducer,
  confirmEmailReducer,
  identifyReducer,
  getUsersReducer,
  setUserSsnReducer,
  updateUserEmailAndPhoneNumberReducer,
} from 'api/account/reducers';
import { loginReducer } from 'api/oauth/reducers';
import { getMembershipsReducer, unregisterMembershipReducer } from 'api/membership/reducers';
import { errorReducer } from 'api/error/reducers';

const appReducer = combineReducers({
  createUser: createUserReducer,
  setUserSsn: setUserSsnReducer,
  updateUserEmailAndPhoneNumber: updateUserEmailAndPhoneNumberReducer,
  getUser: getUserReducer,
  getUsers: getUsersReducer,
  login: loginReducer,
  getMemberships: getMembershipsReducer,
  unregisterMembership: unregisterMembershipReducer,
  passwordResetRequest: passwordResetRequestReducer,
  passwordReset: passwordResetReducer,
  verifyUserAuthenticated: verifyUserAuthenticatedReducer,
  confirmEmail: confirmEmailReducer,
  identify: identifyReducer,
  apiError: errorReducer,
});

function rootReducer(state: any, action: AppActions) {
  // if (action.type === GET_CUSTOMER_RESET) {
  //   console.log('Reset store!');
  //   state = undefined;
  // }
  // if (action.type === CREATE_DOCUMENT_RESET) {
  //   debugger;
  //   console.log('Reset createDocumentReducer!');
  //   const s: ReturnType<typeof rootReducer> = { ...state, documentForCreate: undefined };
  //   state = s;
  // }
  return appReducer(state, action);
}

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(
  rootReducer,
  applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>)
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
