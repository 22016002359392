import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { AppState } from 'store/configureStore';
import { Memberships } from 'components/Memberships';
import { LoadStates } from 'types/typings';
import { getJwtToken } from 'utils/jwtUtils';
import { Layout } from 'Lagacy/Layout';
import { LoginMenu } from '../LoginMenu';
import { getUser, verifyUserAuthenticated } from 'api/account/actions';
import { SubmitButton } from 'input/SubmitButton';
import { HorizontalContainer } from 'components/containers/HorizontalContainer';
import { VerticalContainer } from 'components/containers/VerticalContainer';
import { Messages } from 'components/containers/Messages';
import { useLogin } from 'api/account/confirmLogin';
import { Routes } from 'constants/routes';

export const ManageMembership = (): JSX.Element => {
  const getUserState = useAppSelector((state: AppState) => state.getUser);
  const verifyUserAuthenticatedState = useAppSelector(
    (state: AppState) => state.verifyUserAuthenticated
  );
  //TODO: Merge useLoginUrl and useConfirmLogin 
  const login = useLogin();

  const dispatch = useAppDispatch();
  const jwtToken = getJwtToken();

  useEffect(() => {
    if (login.user) {
      const url = Routes.root;
      // redirect to root! remove signin query parameters
      window.location.href = url;
    }
  }, [login.user]);


  useEffect(() => {
    if (jwtToken) {
      if (getUserState.state === LoadStates.Null) {
        dispatch(getUser());
      }
    }
  }, [dispatch, jwtToken, getUserState.state]);

  useEffect(() => {
    if (jwtToken) {
      if (verifyUserAuthenticatedState.state === LoadStates.Loaded) {
        dispatch(getUser());
      }
    }
  }, [dispatch, jwtToken, verifyUserAuthenticatedState.state]);


  const handleAuthenticateVippsClick = () => {
    login.login(getUserState.user?.phoneNumber!);
  };

  const handleVerifyEmailClick = () => {
    dispatch(verifyUserAuthenticated());
  };

  const isEmailConfirmed = jwtToken && !!getUserState.user && getUserState.user.emailConfirmed;
  const isIdentityConfirmed = true;
  //const isIdentityConfirmed = jwtToken && !!getUserState.user && getUserState.user.ssnConfirmed;
  const isLoggedInAndUserConfirmed = isEmailConfirmed && isIdentityConfirmed && jwtToken;
  const loginMenu = <LoginMenu />;

  return (
    <Layout loginMenu={loginMenu}>
      <HorizontalContainer title={isLoggedInAndUserConfirmed ? 'Medlemsskap' : undefined}>
        <>
          <Messages />
          <VerticalContainer layout="col-md-12">
            {!jwtToken && <>Log inn for å kunne administrere medlemsskap</>}
            {getUserState.user && !isEmailConfirmed && (
              <>
                Du må verifisere epost. Sjekk e-post evt. spam filter for verifiserings e-post for
                nettregister.
                <SubmitButton
                  disabled={false}
                  onClickSave={handleVerifyEmailClick}
                  submitButtonText="Send e-post"
                />
              </>
            )}
            {getUserState.user && !isIdentityConfirmed && (
              <>
                Du må verifisere deg med Vipps Login.
                <SubmitButton
                  disabled={false}
                  onClickSave={handleAuthenticateVippsClick}
                  submitButtonText="Start Vipps verifisering"
                />
              </>
            )}
            {isLoggedInAndUserConfirmed && (
              <>
                Bla bla her kan du slette medlemsskap
                <Memberships />
              </>
            )}
          </VerticalContainer>
        </>
      </HorizontalContainer>
    </Layout>
  );
};
