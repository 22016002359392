import React, { useEffect, useState } from 'react';
import { IFlowState, useFlowState } from 'hooks/flowState';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { passwordReset } from 'api/account/actions';
import { IPasswordResetRequest } from 'api/account/typings';
import { useSearchParams } from 'react-router-dom';
import { AppState } from 'store/configureStore';
import { IMessage, UpdateStates } from 'types/typings';
import { Layout } from 'Lagacy/Layout';
import { Input } from 'input/Input';
import { Button } from 'input/Button';
import { HorizontalContainer } from 'components/containers/HorizontalContainer';
import { VerticalContainer } from 'components/containers/VerticalContainer';
import { globalStyles } from 'utils/styleUtils';
import { Messages } from 'components/containers/Messages';
import { useDispatch } from 'react-redux';
import { API_MASSAGE_ADD } from 'api/error/actionTypes';

const defaultflowState: IFlowState = {
  inputValidation: [],
  errorHref: React.createRef<HTMLDivElement>(),
};

export const PasswordReset = (): JSX.Element => {
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const globalClasses = globalStyles();
  const [flowState, setValidation, isValid] = useFlowState(defaultflowState);
  const [searchParams] = useSearchParams();
  const passwordResetState = useAppSelector((state: AppState) => state.passwordReset);
  const [state, setState] = useState<IPasswordResetRequest>({
    userId: searchParams.get('userId')!,
    code: searchParams.get('code')!,
  });

  const handleFieldChange = (name: string, value: string | number | undefined): void => {
    setState((prev) => {
      return { ...prev, [name]: value };
    });
  };

  useEffect(() => {
    if (passwordResetState.state === UpdateStates.Updated) {
      const message: IMessage = {
        status: "200",
        error: "Passord endret!",
      }
      dispatch({ type: API_MASSAGE_ADD, payload: message });
    }
  }, [passwordResetState.state, dispatch])

  const handleSaveButtonClick = (): void => {
    // Verify Required
    if (isValid()) {
      appDispatch(passwordReset(state));
    }
  };

  return (
    <Layout loginMenu>
      <HorizontalContainer>
        <>
          <Messages />
          <VerticalContainer title="Resett passord">
            <ol className={globalClasses.orderedList}>
              {(!state.userId || !state.code) && (
                <li>
                  Du har kommet hit uten å trykke på lenken i e-posten. Passordet må resettes fra
                  e-posten.
                </li>
              )}
              {passwordResetState.state === UpdateStates.Error && <li>Resett passord feilet</li>}
              {passwordResetState.state === UpdateStates.Updated && <li>Passord oppdatert!</li>}
              {state.userId && state.code && passwordResetState.state === UpdateStates.Null && (
                <>
                  <li>
                    <Input
                      required={true}
                      placeholder="passord"
                      type="password"
                      maxLength={200}
                      minLength={1}
                      value={state.password}
                      name="login_password"
                      validation={setValidation}
                      onChange={(value) => handleFieldChange('password', value)}
                      flowState={flowState}
                    />
                  </li>
                  <li>
                    <Input
                      required={true}
                      placeholder="bekreft passord"
                      type="password"
                      maxLength={200}
                      minLength={1}
                      value={state.confirmPassword}
                      name="login_confirmPassword"
                      validation={setValidation}
                      expression={(value) => state.password === value}
                      onChange={(value) => handleFieldChange('confirmPassword', value)}
                      flowState={flowState}
                    />
                  </li>
                  <li>
                    <Button
                      buttonText="Resett"
                      onClickSave={() => handleSaveButtonClick()}
                      state={passwordResetState.state}
                    />
                  </li>
                </>
              )}
            </ol>
          </VerticalContainer>
          <VerticalContainer>
            <section className="social" id="socialLoginForm">
              <h2>Sikkerhet</h2>
              Vi jobber for å sikre at våre tjenester og løsninger er sikre i bruk. Nettregister.no
              er SSL-kryptert for din sikkerhet.
            </section>
            <section className="social" id="socialLoginForm">
              <h2>Hjelp?</h2>
              Kontakt oss på{' '}
              <span className="glyphicons glyphicons-envelope" aria-hidden="true"></span>
              <a href="mailto:support@nettregister.no">support@nettregister.no</a> for å få
              opprettet bruker.
            </section>
          </VerticalContainer>
        </>
      </HorizontalContainer>
    </Layout>
  );
};
