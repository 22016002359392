import React from 'react';
import { createUseStyles } from 'react-jss';
import { fontSizes, ITheme } from 'constants/styles';

interface IProps {
  htmlFor: string;
  label?: string;
  errorHref?: React.RefObject<HTMLDivElement>;
  error?: boolean
}

export const InputLabel = ({ errorHref, label, htmlFor, error = false }: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      {label && (
        <span ref={errorHref} className={`${classes.label} ${error ? classes.error : ''}`}>
          <label htmlFor={htmlFor}>{label}</label>
        </span>
      )}
    </>
  );
};

const useStyles = createUseStyles((theme: ITheme) => ({
  inputLabel: {
    width: fontSizes.formLabelWidth,
  },
  label: {
    position: 'absolute',
    left: '5px',
    bottom: '25px',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
    '& label': {
      fontWeight: 'inherit'
    }
  },
  error: {
    color: theme.errorColor,
  },

}));
