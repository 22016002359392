import { ITheme } from 'constants/styles';
import { createUseStyles } from 'react-jss';

export type glyphicon = 'leaf' | 'log-out' | 'log-in' | 'user' | 'right-arrow' | 'phone-alt';

export const glyphiconStyles = createUseStyles((theme: ITheme) => ({
  glyphicon: {
    '&:before': {
      position: 'relative',
      top: '2px',
      color: 'inherit',
      marginRight: '3px',
      display: 'inline-block',
      fontFamily: 'Glyphicons Regular',
    },
  },
  leaf: {
    '&:before': {
      content: glyphicons.leaf,
    },
  },
  'log-out': {
    '&:before': {
      content: glyphicons['log-out'],
    },
  },
  'log-in': {
    '&:before': {
      content: glyphicons['log-in'],
    },
  },
  user: {
    '&:before': {
      content: glyphicons.user,
    },
  },
  'right-arrow': {
    '&:before': {
      content: glyphicons['right-arrow'],
    },
  },
  'phone-alt': {
    '&:before': {
      content: glyphicons['phone-alt'],
    },
  },
  envelope: {
    '&:before': {
      content: glyphicons.envelope,
    },
  },
  users: {
    '&:before': {
      content: glyphicons.users,
      left: '-5px',
    },
  },
}));

// For more Glyphicons
// \src\Lagacy\glyphicons.css

interface IGlyphicons {
  house: string;
  calendar: string;
  email: string;
  mobile: string;
  phone: string;
  remove: string;
  hourglass: string;
  valid: string;
  temple: string;
  spinner: string;
  leaf: string;
  'log-out': string;
  'log-in': string;
  user: string;
  'right-arrow': string;
  'phone-alt': string;
  envelope: string;
  users: string;
}

export const glyphicons: IGlyphicons = {
  house: '"\\e021"',
  calendar: '"\\e046"',
  email: '"\\2709"',
  mobile: '"\\e164"',
  phone: '"\\e442"',
  remove: '"\\e193"',
  hourglass: '"\\231b"',
  valid: '"\\e194"',
  temple: '"\\e304"',
  spinner: '"\\f110"',
  leaf: '"\\e002"',
  'log-out': '"\\e388"',
  'log-in': '"\\e387"',
  user: '"\\e004"',
  'right-arrow': '"\\e212"',
  'phone-alt': '"\\e442"',
  envelope: '"\\2709"',
  users: '"\\e500"',
};
