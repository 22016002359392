import React from 'react';
import { createUseStyles } from 'react-jss';
import './bootstrap.css';
import './glyphicons.css';
import './font-awesome.css';
import './Site.css';
import { ITheme } from 'constants/styles';
import { globalStyles } from 'utils/styleUtils';
import { HorizontalContainer } from 'components/containers/HorizontalContainer';
import { VerticalContainer } from 'components/containers/VerticalContainer';
import { glyphiconStyles } from 'styles/glyphiconsRegular';

interface IProps {
  children: React.ReactNode;
  loginMenu: React.ReactNode;
}

interface IOptions {
  isMobile: boolean;
}

export const Layout = ({ children, loginMenu }: IProps): JSX.Element => {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const glyphiconClasses = glyphiconStyles();
  return (
    <>
      <nav className={`${classes.navbar} ${classes.navbarDefault}`}>
        <div className={`${globalClasses.container} ${globalClasses.flexContainer}`}>
          <HorizontalContainer>
            <VerticalContainer>
              <a className={classes.navbarBrand} href="/">
                <img alt="brand" src="NettRegister-Logo-grey-xsmall.png" />
                nettregister.no
              </a>
            </VerticalContainer>
            <VerticalContainer>{loginMenu}</VerticalContainer>
          </HorizontalContainer>
        </div>
      </nav>
      <div
        className={`${globalClasses.container}  ${globalClasses.body} ${globalClasses.flexContainer}`}
      >
        {children}
      </div>
      <footer>
        <div className={`${globalClasses.container} ${globalClasses.flexContainer}`}>
          <div className={`${globalClasses.colMd3} ${globalClasses.left}`}>
            &copy; {new Date().getFullYear()} - North Web AS
          </div>
          <div className={`${globalClasses.colMd3} ${globalClasses.center}`}>
            <b>Org. nr.</b> 914 963 427
          </div>
          <div
            className={`${globalClasses.colMd3} ${globalClasses.center} ${glyphiconClasses.glyphicon} ${glyphiconClasses.envelope}`}
          >
            <a href="mailto:post@nettregister.no">post@nettregister.no</a>
          </div>
          <div
            className={`${globalClasses.colMd3} ${globalClasses.right} ${glyphiconClasses.glyphicon} ${glyphiconClasses['phone-alt']}`}
          >
            40 09 09 11
          </div>
        </div>
      </footer>
    </>
  );
};

export const useStyles = createUseStyles((theme: ITheme) => ({
  nav: {
    listStyle: 'none',
    paddingLeft: 0,
    marginBottom: 0,
    '& > li': {
      position: 'relative',
      display: 'block',
      whiteSpace: 'nowrap',
      padding: '0 10px ',
      '@media (min-width: 720px)': {
        display: 'inline-block',

      }
    },
    '& > li > a': {
      position: 'relative',
      display: 'inline-block',

      textDecoration: 'none',
      '&:hover': {
        backgroundColor: theme.backgroundColorHover,
      },
    },
    '& li > a, li > a:hover, li > a:focus': {
      color: theme.fontColorSecondary,
    },
  },
  navbar: {
    position: 'relative',
    minHeight: '60px',
    marginBottom: '20px',
    border: '1px solid transparent',
    '@media (min-width: 720px)': {
      borderRadius: '4px',
    },
  },
  navbarDefault: {
    backgroundColor: '#f6f6f6',
    borderBottom: '1px solid #d0d0d0',
  },
  navbarBrand: {
    color: theme.labelFontColor,
    display: 'block',
    padding: '10px 0 0 0',
    fontSize: '18px',
    textDecoration: 'none !important',
    '&:visited,:link, :active, , :hover': {
      color: theme.labelFontColor,
      textDecoration: 'none !important',
    },
  },
  navbarRight: {
    '@media (min-width: 720px)': {
      float: 'right',
      marginRight: '-15px',
    },
  },
  navbarNav: {
    margin: '0',
    '& > li > a': {
      color: theme.labelFontColor,
      cursor: 'pointer',
    },
    '@media (min-width: 720px)': {
      // '& > li ': {
      //   float: 'left',
      // },
      '& > li > a': {
        paddingTop: '15px',
        // paddingBottom: 0,
      },
    },
  },
}));
