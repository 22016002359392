import { getUsers, passwordResetRequest, setUserSsn, updateUserEmailAndPhoneNumber } from 'api/account/actions';
import { IUserResponse } from 'api/account/typings';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { Input } from 'input/Input';
import { useEffect, useState } from 'react';
import { AppState } from 'store/configureStore';
import { tableStyles } from 'styles/table';
import { LoadStates, UpdateStates } from 'types/typings';
import { validatePhoneNumber, validateSsn } from 'utils/stringUtils';
import { Spinner } from './spinner';
import { DropdownMenu } from 'input/DropdownMenu';
import { glyphiconStyles } from 'styles/glyphiconsRegular';
import { createUseStyles } from 'react-jss';
import { ITheme } from 'constants/styles';
import { useMessage } from 'hooks/actions';
import { useConfirmEmail } from 'api/account/confirmEmail';
import DataTable from 'react-data-table-component';
import { useUserTable } from 'hooks/userTable';
import { UserRow } from 'hooks/userRow';

export interface IState {
  userId?: string;
  ssn?: string;
  phoneNumber?: string;
  email?: string
}

export const Users = (): JSX.Element => {
  const getUsersState = useAppSelector((state: AppState) => state.getUsers);
  const setUserSsnState = useAppSelector((state: AppState) => state.setUserSsn);
  const updateUserEmailAndPhoneNumberState = useAppSelector((state: AppState) => state.updateUserEmailAndPhoneNumber);
  const passwordResetRequestState = useAppSelector((state: AppState) => state.passwordResetRequest);
  const initialState: IState = {};
  const [state, setState] = useState<IState>(initialState);
  const [filter, setFilter] = useState<string>();
  const [state2, setState2] = useState<IState[]>([]);
  const tableClasses = tableStyles();
  const glyphiconClasses = glyphiconStyles();
  const classes = useStyles();
  const { isLoading, setIsLoading, isError, confirmEmail } = useConfirmEmail();
  const dispatch = useAppDispatch();
  const [setMessage] = useMessage();

  useEffect(() => {
    if (setUserSsnState.state === UpdateStates.Updated) {
      dispatch(getUsers());
    }
  }, [dispatch, setUserSsnState.state]);

  useEffect(() => {
    if (getUsersState.state === LoadStates.Null) {
      dispatch(getUsers());
    }
  }, [dispatch, getUsersState.state]);

  useEffect(() => {
    if (updateUserEmailAndPhoneNumberState.state === UpdateStates.Updated) {
      setMessage({ status: '200', error: 'E-post og telefonnummer oppdatert' });
    }
  }, [setMessage, updateUserEmailAndPhoneNumberState.state]);

  useEffect(() => {
    if (passwordResetRequestState.state === UpdateStates.Updated) {
      setMessage({ status: '200', error: 'Reset passord epost sendt' });
    }
  }, [setMessage, passwordResetRequestState.state]);

  useEffect(() => {
    if (isLoading && !isError) {
      setMessage({ status: '200', error: 'Bekreft epostadresse epost sendt' });
      setIsLoading(null);
    }
  }, [setMessage, isLoading, isError, setIsLoading, passwordResetRequestState.state]);

  const handleSsnClick = (userId: string) => {
    setState((prev) => {
      return { ...prev, userId };
    });
  };

  const handleSsnChange = (ssn: string) => {
    setState((prev) => {
      return { ...prev, ssn };
    });
  };

  const handleResetPasswordClicked = (user: IUserResponse) => {
    // Reset password
    dispatch(passwordResetRequest({ email: user.email, phoneNumber: user.phoneNumber }));
  };

  const handleConfirmRmailClicked = (user: IUserResponse) => {
    // Confirm Email
    confirmEmail(user.id);
  };

  const handleKeyEnter = (state: IState) => {
    const user = getUsersState.users.find(u => u.id === state.userId);
    if (user && state.userId && window.confirm(`Vil du oppdatere e-post og telefonnummer for ${user.fullName}?`)) {
      dispatch(updateUserEmailAndPhoneNumber({ userId: state.userId, email: state.email, phoneNumber: state.phoneNumber }));
    }
  };

  const handleChange = (userId: string, email: string, phoneNumber: string) => {
    setState2((prev) => {
      let current = prev.find(r => r.userId === userId);
      if (!current) {
        current = { userId, email, phoneNumber };
        return prev.concat(current)
      }
      const index = prev.indexOf(current);

      return prev.map((p, i) => {
        if (index === i) {
          return { ...current, email, phoneNumber };
        }
        return p;
      })
    });
  }

  const handleFilterUsers = (value: string | number) => {
    setFilter((value as string).toLocaleUpperCase());
  }

  const handleSsnKeyEnter = (user: IUserResponse) => {
    if (state && state.ssn && validateSsn(state.ssn)) {
      if (window.confirm(`Vil du oppdatere personnummeret til ${user.fullName}?`)) {
        dispatch(setUserSsn({ userId: user.id, ssn: state.ssn }));
      }
    }
  };
  const [columns, expandableRowDisabled] = useUserTable()
  return (
    <div className="row">
      <div className="col-md-12">
        {getUsersState.state === LoadStates.Loading ? (
          <Spinner />
        ) : (
          <div>
            <Input label='Filtrer brukere på navn, telefon eller e-post'
              name='Search'
              onChange={handleFilterUsers}
            />
            <DataTable
              columns={columns}
              data={getUsersState.users.filter(u =>
                !filter
                || u.fullName.toLocaleUpperCase().indexOf(filter) >= 0
                || u.phoneNumber.toLocaleUpperCase().indexOf(filter) >= 0
                || u.email.toLocaleUpperCase().indexOf(filter) >= 0)}
              pagination
              paginationPerPage={10}
              defaultSortFieldId={1}
              expandableRows
              // expandableRowDisabled={expandableRowDisabled}
              expandableRowsComponent={UserRow}
              expandableRowsComponentProps={{
                onChange: (userId: string, email: string, phoneNumber: string) => handleChange(userId, email, phoneNumber),
                onKeyEnter: handleKeyEnter,
                getState: (userId: string) => state2!.find(s => s.userId === userId)
              }}
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
              dense
            />
            {/* <table className={tableClasses.table}>
              <thead>
                <tr className={tableClasses.trHead}>
                  <th>Navn</th>
                  <th>Telefon</th>
                  <th className={tableClasses.tdCompact}>E-post</th>
                  <th className={tableClasses.tdCompact}>E-post?</th>
                  <th className={tableClasses.tdLandscape}>Personnummer?</th>
                  <th
                    className={`${glyphiconClasses.glyphicon} ${glyphiconClasses.envelope} ${classes.right}`}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {getUsersState.users.map((user) => {
                  return (
                    <tr key={user.id} className={tableClasses.tr}>
                      <td>{user.fullName}</td>
                      <td className={validatePhoneNumber(user.phoneNumber) ? "" : classes.invalid}>{user.phoneNumber}</td>
                      <td className={tableClasses.tdCompact}>{user.email}</td>
                      <td className={tableClasses.tdCompact}>
                        {user.emailConfirmed ? 'Ja' : 'Nei'}
                      </td>
                      <td
                        className={tableClasses.tdLandscape}
                        onClick={() => handleSsnClick(user.id)}
                      >
                        {user.ssnConfirmed ? 'Ja' : user.id !== state?.userId ? 'Nei *' : undefined}
                        {user.id === state?.userId && (!user.ssnConfirmed || !user.ssnHasValue) && (
                          <Input
                            name="Ssn"
                            onChange={(value) => handleSsnChange(value as string)}
                            onKeyEnter={() => handleSsnKeyEnter(user)}
                          />
                        )}
                      </td>
                      <td>
                        <div className={classes.right}>
                          <DropdownMenu
                            text="Velg"
                            options={
                              user.emailConfirmed
                                ? [
                                  {
                                    onItemClicked: () => handleResetPasswordClicked(user),
                                    text: 'Reset passord',
                                    glyphicon: 'right-arrow',
                                  },
                                ]
                                : [
                                  {
                                    onItemClicked: () => handleResetPasswordClicked(user),
                                    text: 'Reset passord',
                                    glyphicon: 'right-arrow',
                                  },
                                  {
                                    onItemClicked: () => handleConfirmRmailClicked(user),
                                    text: 'Bekreft epost',
                                    glyphicon: 'right-arrow',
                                  },
                                ]
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table> */}
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = createUseStyles((theme: ITheme) => ({
  right: {
    textAlign: 'right',
  },
  invalid: () => ({
    color: theme.errorColor
  })
}));
