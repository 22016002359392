import React, { useState, useEffect } from 'react';
import { AppState } from 'store/configureStore';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { ILoginRequest } from 'api/oauth/typings';
import { IFlowState, useFlowState } from 'hooks/flowState';
import { login } from 'api/oauth/actions';
import { LoadStates } from 'types/typings';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'Lagacy/Layout';
import { LoginMenu } from 'components/LoginMenu';
import { Routes } from 'constants/routes';
import { Button } from 'input/Button';
import { Input } from 'input/Input';
import { HorizontalContainer } from 'components/containers/HorizontalContainer';
import { VerticalContainer } from 'components/containers/VerticalContainer';
import { globalStyles } from 'utils/styleUtils';
import { CLEAR_ERROR } from 'api/actionsTypes';
import { useDispatch } from 'react-redux';
import { Messages } from 'components/containers/Messages';
import { validateEmail } from 'utils/stringUtils';

interface IProps {
  redirect?: string;
}

const defaultflowState: IFlowState = {
  inputValidation: [],
  errorHref: React.createRef<HTMLDivElement>(),
};

export const LoginForm = ({ redirect = '/' }: IProps): JSX.Element => {
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const globalClasses = globalStyles();
  const [state, setState] = useState<ILoginRequest>({
    grant_type: 'password',
  });
  var navigate = useNavigate();

  const [flowState, setValidation, isValid] = useFlowState(defaultflowState);
  const loginState = useAppSelector((state: AppState) => state.login);

  useEffect(() => {
    dispatch({ type: CLEAR_ERROR });
  }, [dispatch]);

  useEffect(() => {
    if (loginState.state === LoadStates.Loaded) {
      navigate(redirect);
    }
  }, [appDispatch, navigate, redirect, loginState.state]);

  const handleLoginClick = (): void => {
    // Verify Required
    if (isValid()) {
      appDispatch(login(state));
    }
  };

  const handleResetPasswordClick = (): void => {
    // Verify Required
    navigate(Routes.resetPasswordRequest);
  };

  const handleFieldChange = (name: string, value: string | number | undefined): void => {
    setState((prev) => {
      return { ...prev, [name]: value };
    });
  };

  return (
    <Layout loginMenu={<LoginMenu />}>
      <HorizontalContainer title="Logg inn">
        <>
          <Messages />
          <VerticalContainer>
            <ol className={globalClasses.orderedList}>
              <li>
                <Input
                  placeholder="brukernavn / e-post"
                  maxLength={200}
                  minLength={8}
                  value={state.userName}
                  name="login_userName"
                  required
                  expression={validateEmail}
                  validation={setValidation}
                  onChange={(value) => handleFieldChange('userName', value)}
                  onKeyEnter={handleLoginClick}
                  flowState={flowState}
                />
              </li>
              <li>
                <Input
                  placeholder="passord"
                  type="password"
                  maxLength={200}
                  minLength={1}
                  required
                  value={state.password}
                  name="login_password"
                  validation={setValidation}
                  onChange={(value) => handleFieldChange('password', value)}
                  onKeyEnter={handleLoginClick}
                  flowState={flowState}
                />
              </li>
              <li>
                <div className={globalClasses.flexContainer}>
                  <Button
                    state={loginState.state}
                    buttonText="Login"
                    onClickSave={() => handleLoginClick()}
                  />
                  <Button
                    buttonText="Glemt passord"
                    onClickSave={() => handleResetPasswordClick()}
                    state={LoadStates.Null}
                  />
                </div>
              </li>
            </ol>
          </VerticalContainer>
          <VerticalContainer>
            <section className="social" id="socialLoginForm">
              <h2>Sikkerhet</h2>
              Vi jobber for å sikre at våre tjenester og løsninger er sikre i bruk. Nettregister.no
              er SSL-kryptert for din sikkerhet.
            </section>
            <section className="social" id="socialLoginForm">
              <h2>Hjelp?</h2>
              Kontakt oss på{' '}
              <span className="glyphicons glyphicons-envelope" aria-hidden="true"></span>
              <a href="mailto:support@nettregister.no">support@nettregister.no</a> for å få
              opprettet bruker.
            </section>
          </VerticalContainer>
        </>
      </HorizontalContainer>
    </Layout>
  );
};
