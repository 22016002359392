import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import { store } from 'store/configureStore';
import { ILoginState, IUserInfo } from 'api/oauth/typings';
import { LoadStates } from 'types/typings';

export const accessTokenName: string = 'access_token';

export const userInfo = (token: string): IUserInfo => {
  const userInfo: IUserInfo = jwt_decode(token);
  return userInfo;
};

export const getJwtToken = (): string | undefined => {
  const cookies = new Cookies();
  const cookie = cookies.get(accessTokenName);
  if (cookie) {
    return cookie;
  }
  return undefined;
};

export const getLoginState = (): ILoginState => {
  const accessToken = getJwtToken();
  if (accessToken) {
    return {
      state: LoadStates.Loaded,
      payload: {
        access_token: accessToken,
        expires_in: -1,
        token_type: 'bearer',
      },
      jwtToken: userInfo(accessToken),
    };
  }
  return store.getState().login;
};
