import { createUseStyles } from 'react-jss';
import { ITheme } from 'constants/styles';
import { globalStyles, width } from 'utils/styleUtils';

interface IProps {
  children: React.ReactNode;
  title?: string;
  layout?: 'col-md-6' | 'col-md-12' | 'col-md-4' | 'col-md-3';
}

interface IOptions {
  layout: 'col-md-6' | 'col-md-12' | 'col-md-4' | 'col-md-3';
}

export const HorizontalContainer = ({ children, layout = 'col-md-12', title }: IProps): JSX.Element => {
  const classes = useStyles({ layout });
  const globalClasses = globalStyles();

  return (
    <div className={`${classes.horizontal} ${globalClasses.flexContainer}`}>

      {title && <div className={classes.title} >
        <h1 >{title}</h1>
      </div>}
      {children}
    </div>);
};

const useStyles = createUseStyles((theme: ITheme) => ({
  horizontal: ({ layout }: IOptions) => ({
    position: 'relative',
    width: '100%',
    '@media (min-width: 720px)': {
      width: `${width(layout)}%`,
      // float: 'left',
    },
  }),
  title: {
    width: '100%',
    padding: '0',
    display: 'inline-block',
    '& h1': {
      marginTop: '0'
    },
    '@media (min-width: 720px)': {
      padding: '0 15px 10px 15px',
    },
  }
}));
