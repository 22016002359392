import { LoadStates, CreateStates, UpdateStates } from 'types/typings';
import {
  IConfirmEmailState,
  ICreateUserState,
  IGetUsersState,
  IGetUserState,
  IPasswordResetRequestState,
  IPasswordResetState,
  ISetUserSsnState,
  IUpdateUserUpdateEmailAndPhoneNumberState,
  IVippsAuthenticateState,
  IVippsIdentifyState,
} from './typings';
import {
  CREATE_USER_ERROR,
  CREATE_USER_SUCCESS,
  CREATING_USER,
  CreateUserActionTypes,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  GETTING_USER,
  GetUserActionTypes,
  PasswordResetRequestActionTypes,
  PASSWORD_RESET_REQUEST_SUCCESS,
  PASSWORD_RESET_REQUESTING,
  PASSWORD_RESET_REQUEST_ERROR,
  PasswordResetActionTypes,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESETTING,
  PASSWORD_RESET_ERROR,
  VippsAuthenticateActionTypes,
  VIPPS_AUTHENTICATE_SUCCESS,
  VIPPS_AUTHENTICATING,
  VIPPS_AUTHENTICATE_ERROR,
  ConfirmEmailActionTypes,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAILING,
  CONFIRM_EMAIL_ERROR,
  VippsIdentifyActionTypes,
  VIPPS_IDENTIFY_ERROR,
  VIPPS_IDENTIFYING,
  VIPPS_IDENTIFY_SUCCESS,
  GetUsersActionTypes,
  GET_USERS_SUCCESS,
  GETTING_USERS,
  GET_USERS_ERROR,
  UserSsnSetActionTypes,
  USER_SSN_SET_SUCCESS,
  USER_SSN_SETTING,
  USER_SSN_SET_ERROR,
  UserEmailAndPhoneNumberSetActionTypes,
  USER_EMAIL_AND_PHONENUMBER_SET_SUCCESS,
  USER_EMAIL_AND_PHONENUMBER_SETTING,
  USER_EMAIL_AND_PHONENUMBER_SET_ERROR,
} from './actionsTypes';
import { SIGN_OUT } from 'api/actionsTypes';

export const CREATE_USER_INITIAL_STATE: ICreateUserState = {
  state: CreateStates.Null,
};

export const createUserReducer = (
  state = CREATE_USER_INITIAL_STATE,
  action: CreateUserActionTypes
): ICreateUserState => {
  switch (action.type) {
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        state: CreateStates.Created,
      };
    case CREATING_USER:
      return {
        ...state,
        state: CreateStates.Creating,
      };
    case CREATE_USER_ERROR:
      return {
        ...state,
        state: CreateStates.Error,
      };
    default:
      return state;
  }
};

export const SET_USER_SSN_INITIAL_STATE: ISetUserSsnState = {
  state: UpdateStates.Null,
};

export const setUserSsnReducer = (
  state = SET_USER_SSN_INITIAL_STATE,
  action: UserSsnSetActionTypes
): ISetUserSsnState => {
  switch (action.type) {
    case USER_SSN_SET_SUCCESS:
      return {
        ...state,
        user: action.payload,
        state: UpdateStates.Updated,
      };
    case USER_SSN_SETTING:
      return {
        ...state,
        state: UpdateStates.Updating,
      };
    case USER_SSN_SET_ERROR:
      return {
        ...state,
        state: UpdateStates.Error,
      };
    default:
      return state;
  }
};

export const SET_USER_EMAIL_AND_PHONENUMBER_INITIAL_STATE: IUpdateUserUpdateEmailAndPhoneNumberState = {
  state: UpdateStates.Null,
};

export const updateUserEmailAndPhoneNumberReducer = (
  state = SET_USER_EMAIL_AND_PHONENUMBER_INITIAL_STATE,
  action: UserEmailAndPhoneNumberSetActionTypes
): IUpdateUserUpdateEmailAndPhoneNumberState => {
  switch (action.type) {
    case USER_EMAIL_AND_PHONENUMBER_SET_SUCCESS:
      return {
        ...state,
        user: action.payload,
        state: UpdateStates.Updated,
      };
    case USER_EMAIL_AND_PHONENUMBER_SETTING:
      return {
        ...state,
        state: UpdateStates.Updating,
      };
    case USER_EMAIL_AND_PHONENUMBER_SET_ERROR:
      return {
        ...state,
        state: UpdateStates.Error,
      };
    default:
      return state;
  }
};

export const GET_USER_INITIAL_STATE: IGetUserState = {
  state: LoadStates.Null,
};

export const getUserReducer = (
  state = GET_USER_INITIAL_STATE,
  action: GetUserActionTypes
): IGetUserState => {
  switch (action.type) {
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        state: LoadStates.Loaded,
      };
    case GETTING_USER:
      return { ...state, state: LoadStates.Loading };
    case SIGN_OUT:
      return GET_USER_INITIAL_STATE;
    case GET_USER_ERROR:
      return {
        ...state,
        state: LoadStates.Error,
      };
    default:
      return state;
  }
};

export const GET_USERS_INITIAL_STATE: IGetUsersState = {
  state: LoadStates.Null,
  users: [],
};

export const getUsersReducer = (
  state = GET_USERS_INITIAL_STATE,
  action: GetUsersActionTypes
): IGetUsersState => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        state: LoadStates.Loaded,
      };
    case GETTING_USERS:
      return { ...state, state: LoadStates.Loading };
    case GET_USERS_ERROR:
      return {
        ...state,
        state: LoadStates.Error,
      };
    default:
      return state;
  }
};

const PASSWORD_RESET_REQUEST_INITIAL_STATE: IPasswordResetRequestState = {
  state: UpdateStates.Null,
};

export const passwordResetRequestReducer = (
  state = PASSWORD_RESET_REQUEST_INITIAL_STATE,
  action: PasswordResetRequestActionTypes
): IPasswordResetRequestState => {
  switch (action.type) {
    case PASSWORD_RESET_REQUEST_SUCCESS:
      return {
        ...state,
        login: action.payload,
        state: UpdateStates.Updated,
      };
    case PASSWORD_RESET_REQUESTING:
      return { ...state, state: UpdateStates.Updating };
    case PASSWORD_RESET_REQUEST_ERROR:
      return {
        ...state,
        state: UpdateStates.Error,
      };
    default:
      return state;
  }
};

const PASSWORD_RESET_INITIAL_STATE: IPasswordResetState = {
  state: UpdateStates.Null,
};

export const passwordResetReducer = (
  state = PASSWORD_RESET_INITIAL_STATE,
  action: PasswordResetActionTypes
): IPasswordResetState => {
  switch (action.type) {
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        state: UpdateStates.Updated,
      };
    case PASSWORD_RESETTING:
      return { ...state, state: UpdateStates.Updating };
    case PASSWORD_RESET_ERROR:
      return {
        ...state,
        state: UpdateStates.Error,
      };
    default:
      return state;
  }
};

const VIPPS_AUTHENTICATE_INITIAL_STATE: IVippsAuthenticateState = {
  state: LoadStates.Null,
};

export const verifyUserAuthenticatedReducer = (
  state = VIPPS_AUTHENTICATE_INITIAL_STATE,
  action: VippsAuthenticateActionTypes
): IVippsAuthenticateState => {
  switch (action.type) {
    case VIPPS_AUTHENTICATE_SUCCESS:
      return {
        ...state,
        state: LoadStates.Loaded,
      };
    case VIPPS_AUTHENTICATING:
      return { ...state, state: LoadStates.Loading };
    case VIPPS_AUTHENTICATE_ERROR:
      return {
        ...state,
        state: LoadStates.Error,
      };
    default:
      return state;
  }
};

const CONFIRM_EMAIL_INITIAL_STATE: IConfirmEmailState = {
  state: UpdateStates.Null,
};

export const confirmEmailReducer = (
  state = CONFIRM_EMAIL_INITIAL_STATE,
  action: ConfirmEmailActionTypes
): IConfirmEmailState => {
  switch (action.type) {
    case CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        state: UpdateStates.Updated,
      };
    case CONFIRM_EMAILING:
      return { ...state, state: UpdateStates.Updating };
    case CONFIRM_EMAIL_ERROR:
      return {
        ...state,
        state: UpdateStates.Error,
      };
    default:
      return state;
  }
};

const VIPPS_IDENTIFY_INITIAL_STATE: IVippsIdentifyState = {
  state: LoadStates.Null,
};

export const identifyReducer = (
  state = VIPPS_IDENTIFY_INITIAL_STATE,
  action: VippsIdentifyActionTypes
): IVippsIdentifyState => {
  switch (action.type) {
    case VIPPS_IDENTIFY_SUCCESS:
      return {
        ...state,
        user: action.payload,
        state: LoadStates.Loaded,
      };
    case VIPPS_IDENTIFYING:
      return { ...state, state: LoadStates.Loading };
    case VIPPS_IDENTIFY_ERROR:
      return {
        ...state,
        state: LoadStates.Error,
      };
    default:
      return state;
  }
};


