import { ITheme } from 'constants/styles';
import { createUseStyles } from 'react-jss';

interface IProps {
  title: string;
  type: 'information' | 'error';
  message: string;
  onClose: () => void;
  ttl?: number;
}

interface IOptions {
  type: 'information' | 'error';
  ttl: number;
}

export const Message = ({ title, type, message, ttl = 10, onClose }: IProps): JSX.Element => {
  const classes = useStyles({ type, ttl });

  setTimeout(() => onClose(), ttl * 1000);

  return (
    <>
      <div className={classes.message}>
        <div className={classes.titleWrapper}>
          {title && <div className={classes.title}>{title}</div>}
          {onClose && (
            <div className={classes.close} onClick={() => onClose()}>
              &times;
            </div>
          )}
        </div>
        {message}
        <div className={classes.break}>
          <progress className={classes.animation} max={1} value={-1}></progress>
        </div>
      </div>
    </>
  );
};

const useStyles = createUseStyles((theme: ITheme) => ({
  '@keyframes progress': {
    '0%': { width: '100%' },
    '100%': { width: '0%' },
  },
  titleWrapper: {
    display: 'flex',
    width: '100%',
  },
  break: {
    flexBasis: '100%',
    width: 0,
    display: 'inline-flex',
  },
  animation: ({ type, ttl }: IOptions) => ({
    animationDuration: `${ttl}s`,
    width: '100%',
    height: '1px',
    border: `1px solid ${type === 'error' ? theme.errorColor : 'green'}`,
    animation: '$progress',
  }),
  close: {
    fontSize: 'large',
    '&:hover': {
      color: theme.linkHover,
      cursor: 'pointer',
    },
  },
  message: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: theme.borderColor,
    border: `1px solid ${theme.borderColorContrast}`,
    borderRadius: '4px',
    marginBottom: '5px',
    padding: '2px 15px 3px',
    '@media (min-width: 720px)': {},
    '@media (min-width: 992px)': {},
  },
  title: ({ type }: IOptions) => ({
    color: type === 'error' ? theme.errorColor : theme.fontColorPrimary,
    padding: '0',
    fontSize: 'larger',
    width: '100%',
  }),
}));
